import React, { useState, useEffect, useRef } from "react";
import { useQuery } from "react-apollo-hooks";
import { ContactModule } from "schema/fragments/Contact";
import { useFooterContent } from "hooks/shell";
import { lockBackground, isNonEmptyArray } from "helpers";
import Div100VH from "react-div-100vh";
import CloseButton from "components/CloseButton";
import ContactForm from "components/Forms/Contact";
import Container from "components/Container"
import Heading from "components/Heading";
import LanguageFilter from "components/LanguageFilter"
import LoadingIndicator from "components/LoadingIndicator"
import FooterLink from "components/SiteFooter/FooterLink"

import styles from "./styles.css";


const SiteFooter = () => {
	const contactRef = useRef(null);
	const { data: contactData, error: contactError, loading: contactLoading } = useQuery(ContactModule);
	const [ content ] = useFooterContent();
	const [ isContactOpen, setIsContactOpen] = useState(false);
	const [ isContactConfirmed, setIsContactConfirmed] = useState(false);
	const contactContent = contactData?.moduleContactCollection?.items[0];

	useEffect(() => {
		lockBackground(isContactOpen, contactRef?.current)
	}, [isContactOpen]);

	if (!content) {
		return ""
	}

	const onFormClose = () => {
		setIsContactOpen(false);
		setTimeout(() => setIsContactConfirmed(false), 300);
	};

	const collectionHasItems = (collection) => {
		return collection && isNonEmptyArray(collection.items);
	};

	const hasTier1Links = () => {
		const { followLinksCollection, partnerLinksCollection } = content;
		return collectionHasItems(followLinksCollection) || collectionHasItems(partnerLinksCollection)
	};

	const {
		copyrightLine1,
		copyrightLine2,
		labelContactUs,
		labelFollowLinks,
		labelGroup1Links,
		labelGroup2Links,
		labelPartnerLinks,
		followLinksCollection,
		partnerLinksCollection,
		group1LinksCollection,
		group2LinksCollection
	} = content || {};

	return(
		<div data-component="SiteFooter" className={styles.siteFooter}>
			{content ?
				<Container>
					{hasTier1Links() &&
					<div className={styles.icons}>
						{collectionHasItems(followLinksCollection) &&
						<div className={styles.social}>
							{labelFollowLinks && <h5 className={styles.title}>{labelFollowLinks}</h5>}
							<ul className={styles.iconList}>
								{followLinksCollection.items.map((link, index) => {
									return link.icon &&
									<li className={styles.iconLink} key={index}>
										<FooterLink targetRoute={link.targetRoute} icon={link.icon.url} altText={link.altTextIcon} openInNewTabWindow={link.openInNewTabWindow}  />
									</li>
								})}
							</ul>
						</div>
						}
						{collectionHasItems(partnerLinksCollection) &&
						<div className={styles.partners}>
							{labelPartnerLinks && <h5 className={styles.title}>{labelPartnerLinks}</h5>}
							<ul className={styles.iconList}>
								{partnerLinksCollection.items.map((link, index) => {
									return link.icon &&
									<li className={styles.iconLink} key={index}>
										<FooterLink targetRoute={link.targetRoute} icon={link.icon.url} altText={link.altTextIcon} openInNewTabWindow={link.openInNewTabWindow} />
									</li>
								})}
							</ul>
						</div>
						}
					</div>
					}
					<div className={styles.info}>
						<div className={styles.categories}>
							{collectionHasItems(group1LinksCollection) &&
							<div className={styles.category}>
								{labelGroup1Links && <h5 className={styles.title}>{labelGroup1Links}</h5>}
								<ul className={styles.linkList}>
									{group1LinksCollection.items.map((link, index) => {
										return link.labelLink &&
										<li className={styles.textLink} key={index}>
											<FooterLink targetRoute={link.targetRoute} openInNewTabWindow={link.openInNewTabWindow} label={link.labelLink} />
										</li>
									})}
								</ul>
							</div>
							}
							{collectionHasItems(group2LinksCollection) &&
							<div className={styles.category}>
								<h5 className={styles.title}>{labelGroup2Links}</h5>
								<ul className={styles.linkList}>
									{group2LinksCollection.items.map((link, index) => {
										return link.labelLink &&
										<li className={styles.textLink} key={index}>
											<FooterLink targetRoute={link.targetRoute} openInNewTabWindow={link.openInNewTabWindow} label={link.labelLink} />
										</li>
									})}
								</ul>
							</div>
							}
						</div>
						<div className={styles.categories}>
							<div className={styles.category}>
								<LanguageFilter inFooter/>
							</div>
							<div className={styles.category}>
								<button className={styles.contactButton} onClick={() => setIsContactOpen(true)}>
									<span className={styles.contact}>{labelContactUs}</span>
								</button>
							</div>
						</div>
					</div>
					<div className={styles.copyright}>
						<span>{copyrightLine1}</span><br/>
						<span>{copyrightLine2}</span>
					</div>
				</Container>
				:
				<LoadingIndicator/>
			}

			<aside className={`${styles.contactOverlay} ${isContactOpen ? styles.isOpen : ""}`}>
				<Div100VH className={styles.formLayout}>
					<div ref={contactRef} className={styles.formContainer}>

						{contactContent && !contactError && !contactLoading &&
						<>
							<div className={styles.closeButtonContainer}>
								<CloseButton onClick={onFormClose}/>
							</div>

							{isContactConfirmed
								? 
								<>
									<div className={styles.contactHeading}>
										<Heading type="h2">{contactContent.labelConfirmation}</Heading>
										<div className={styles.copy}>{contactContent.labelConfirmationSubtitle}</div>
									</div>
								</>
								:
								<>
									<div className={styles.contactHeading}>
										<Heading type="h2">{contactContent.title}</Heading>
										<div className={styles.copy}>{contactContent.description}</div>
									</div>

									<ContactForm labels={contactContent} onSuccess={() => setIsContactConfirmed(true)}/>
								</>
							}
						</>
						}
					</div>
				</Div100VH>
			</aside>
		</div>
	);
};

export default SiteFooter;