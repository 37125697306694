import { useEffect } from "react";
import PropTypes from "prop-types";
import { useGlobalState } from "helpers";

let _isRAFScheduled = false;

const getWindowScrollTop = () => window.pageYOffset || document.documentElement.scrollTop;

const ScrollListener = () => {
	const [ , setScrollY ] = useGlobalState("scrollY");

	const onScroll = () => {
		if(!_isRAFScheduled) {
			_isRAFScheduled = true;
			requestAnimationFrame(() => {
				_isRAFScheduled = false;
				setScrollY(getWindowScrollTop());
			});
		}
	};

	useEffect(() => {
		window.addEventListener("scroll", onScroll);

		return () => window.removeEventListener("scroll", onScroll);
	}, []);

	return "";
};

ScrollListener.propTypes = {
	children: PropTypes.node
};

export default ScrollListener;