import gql from "graphql-tag";

const {
	REACT_APP_PREVIEW_API: PREVIEW_API
} = process.env;

export const StoryTellingQuery = gql`
    query StoryTellingQuery($locale: String!, $id: String!) {
		moduleStoryTelling(preview: ${PREVIEW_API === "true"}, locale: $locale, id: $id) {
			title
            labelCallToAction
            targetRoute
            imagesCollection {
                items {
                    title
                    url
                }
            }
            statsCollection {
                items {
                    labelStat
                    value
                }
            }
			tabsCollection(limit: 20) {
				items {
					labelTab
					description
				}
			}
		}
    }
`;