import React from "react";
import PropTypes from "prop-types";
import TextTruncate from "react-text-truncate";

import { isNonEmptyString } from "helpers";

import styles from "./styles.css";

const Synopsis = ({ text, isTruncated }) => (
    isNonEmptyString(text) 
    ? (
    <p data-component="Synopsis" className={styles.synopsis}>
        {isTruncated 
        ? <TextTruncate
            line={5}
            truncateText="…"
            text={text}
        />
        : text
        }
    </p>
    )
    : ""
);

Synopsis.propTypes = {
	text: PropTypes.string,
};

export default Synopsis;