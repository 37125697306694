import React from "react";
import PropTypes from "prop-types";

import styles from "./styles.css";

const NumericCounter = ({ currentItem, totalItems }) => (
	<span data-component="NumericCounter" className={styles.numericCounter}>
		{currentItem + 1} / {totalItems}
	</span>
);

NumericCounter.propTypes = {
	currentItem: PropTypes.number,
	totalItems: PropTypes.number
};

export default NumericCounter;