import React, { useRef, useEffect } from "react";
import PropTypes from "prop-types";
import moment from "moment";
import { isNonEmptyArray } from "helpers";
import { GalleryCard } from "schema";
import TextTruncate from "react-text-truncate";
import { useDictionaryContent } from "hooks/shell";

import ConnectedComponent from "components/ConnectedComponent";
import BasePadding from "components/BasePadding";
import Container from "components/Container";
import Cta from "components/Cta";
import Eyebrow from "components/Eyebrow";
import Heading from "components/Heading";
import Image from "components/Image";
import LocaleLink from "components/LocaleLink";
import ThrowableArea from "components/ThrowableArea";
import VerticalSpacing from "components/VerticalSpacing";

import Arrow from "globals/images/arrow.svg";
import styles from "./styles.css";


const Card = props => {
	const {
		routeSlug,
		featuredImage,
		publishedDate,
		description,
		title,
		category,
		galleryCollection,
		isFirst
	} = props;

	const [ dictionaryContent ] = useDictionaryContent();
	const desktopGalleryRef = useRef(null);
	const mobileGalleryRef = useRef(null);
	const subcategoryKey = `subcategory${category.name}Topic`;
	const subcategoryLabel = subcategoryKey in props ? props[subcategoryKey] : "";
	const categorySlug = category.name.toLowerCase();
	const postRoute = `/${categorySlug}/${routeSlug}`;
	const formattedPublishedDate = moment(publishedDate).format("ll");

	const galleryItems = isNonEmptyArray(galleryCollection.items) ? galleryCollection.items : [];
	const numImages = galleryItems.length;
	const leftovers = numImages % 8;

	const isBig = (position, index) => {
		const threshold = numImages - leftovers;

		if (index >= threshold) {
			if (leftovers === 1 || leftovers === 2) {
				return true;
			}

			if (leftovers === 3) {
				return numImages < 8 || position === 3;
			}

			if (leftovers === 4) {
				return numImages < 8 && (position === 3 || position === 4);
			}

			if (leftovers === 6) {
				return position === 3 || position === 6;
			}
		}

		return position === 3 || position === 8;
	};

	const renderBody = () => {
		return (
			<>
				{featuredImage &&
				<div className={styles.featuredImageDesktop}>
					<LocaleLink to={postRoute}>
						<Image url={featuredImage.url} altText={featuredImage.title} aspectRatio="3x4" />
					</LocaleLink>
				</div>
				}
				<div className={styles.textContent}>
					{subcategoryLabel &&
					<div className={styles.eyebrow}>
						<Eyebrow>{subcategoryLabel}</Eyebrow>
					</div>
					}

					<div className={styles.content}>
						<div className={styles.heading}>
							<LocaleLink to={postRoute}>
								<Heading type="h2">{title}</Heading>
							</LocaleLink>
						</div>

						{featuredImage &&
						<div className={styles.featuredImageMobile}>
							<LocaleLink to={postRoute}>
								<Image url={featuredImage.url} altText={featuredImage.title} aspectRatio="3x4" />
							</LocaleLink>
						</div>
						}

						{description &&
							<p className={styles.description}>
								<TextTruncate
									line={4}
									truncateText="…"
									text={description}
								/>
							</p>
						}
						<div className={styles.date}>{formattedPublishedDate}</div>
						<Cta type="Link">
							<LocaleLink to={postRoute}>
								<Arrow />{dictionaryContent?.labelViewMore}
							</LocaleLink>
						</Cta>
						<hr className={styles.line} />
					</div>
				</div>
			</>
		);
	};

	const renderGallery = (ref) => {
		let patternCount = 0;

		return (
			<div ref={ref} className={styles.galleryContent}>
				{galleryItems.map((item, index) => {
					patternCount = patternCount >= 8 ? 1 : patternCount + 1;
					const isPositionBig = isBig(patternCount, index);

					return (
						<div key={index} data-height={item.height} data-width={item.width} className={`${styles.imageContainer} ${isPositionBig ? styles.bigBoy : ""}`}>
							<Image url={item.url} altText={item.title} aspectRatio={isPositionBig ? null : "1x1"}/>
						</div>
					)
				})}
			</div>
		);
	};

	const setImageWidthsForRef = ref => {
		const imageNodes = ref.current.querySelectorAll(`.${styles.bigBoy}`);
		const galleryHeight = ref.current.getBoundingClientRect().height;

		imageNodes.forEach(node => {
			const nodeData = node.dataset;
			const width = parseInt(nodeData.width, 10);
			const height = parseInt(nodeData.height, 10);


			if (width && height) {
				const scale = galleryHeight / height;
				const scaledWidth = width * scale;
				node.style.width = `${scaledWidth}px`;
			}
		});
	};

	useEffect(() => {
		if (desktopGalleryRef && desktopGalleryRef.current) {
			setImageWidthsForRef(desktopGalleryRef);
		}

		if (mobileGalleryRef && mobileGalleryRef.current) {
			setImageWidthsForRef(mobileGalleryRef);
		}
	}, [desktopGalleryRef, mobileGalleryRef]);

	return (
		<div data-component="GalleryFeedCard" className={`${styles.galleryFeedCard} ${isFirst ? styles.addTopMargin : ""}`}>
			<VerticalSpacing>
				<Container showOverflow>
					<ThrowableArea className={styles.desktopThrow} fullWidth>
						<BasePadding desktop={{ left: true, right: false }}>
							<div className={styles.flexContainer}>
								{renderBody()}
								{renderGallery(desktopGalleryRef)}
							</div>
						</BasePadding>
					</ThrowableArea>

					<div className={styles.mobileContent}>
						<div className={styles.mobileBody}>
							<BasePadding mobile={{ left: false, right: true }}>
								{renderBody()}
							</BasePadding>
						</div>

						<ThrowableArea>
							{renderGallery(mobileGalleryRef)}
						</ThrowableArea>
					</div>
				</Container>
			</VerticalSpacing>
		</div>
	);
};

const GalleryFeedCard = props => {
	const {
		content,
		fetchQuery
	} = props;

	if (content) {
		// If we already have the content for this card (parent query took care of it).
		return (
			<Card {...props.content} />
		)
	} else if (fetchQuery) {
		// If we don't know the content of the card, but have an ID to query it.
		const success = props => {
			return <Card {...props.gallery} />
		};
		return <ConnectedComponent query={GalleryCard} variables={{ id: fetchQuery }} success={success} />
	} else {
		return <></>
	}
};

GalleryFeedCard.propTypes = {
	content: PropTypes.shape({
		routeSlug: PropTypes.string,
		copy: PropTypes.object,
		featuredImage: PropTypes.object,
		publishedDate: PropTypes.string,
		title: PropTypes.string,
		category: PropTypes.object,
		galleryCollection: PropTypes.shape({
			items: PropTypes.arrayOf(PropTypes.shape({
				url: PropTypes.string,
				title: PropTypes.string,
				width: PropTypes.number,
				height: PropTypes.number,
			}))
		})
	})
};

export default GalleryFeedCard;

