import React, { useState, useEffect } from "react";
import { useApolloClient } from "react-apollo-hooks";
import { useDictionaryContent } from "hooks/shell";
import { FilteredPostsQuery } from "schema";
import { documentToPlainTextString } from "@contentful/rich-text-plain-text-renderer";
import { getResultPage, isNonEmptyArray, isNonEmptyString } from "helpers";
import TextTruncate from "react-text-truncate";
import BasePadding from "components/BasePadding";
import Carousel from "components/Carousel";
import Container from "components/Container";
import Heading from "components/Heading";
import Image from "components/Image";
import LoadingIndicator from "components/LoadingIndicator";
import LocaleLink from "components/LocaleLink";
import VerticalSpacing from "components/VerticalSpacing";
import Video from "components/Video";

import styles from "./styles.css";


const RelatedPosts = ({ post: postSlug, postType }) => {
	const apollo = useApolloClient();
	const [isTransitioning, setIsTransitioning] = useState(false);
	const [hasLoaded, setHasLoaded] = useState(false);
	const [posts, setPosts] = useState([]);
	const [dictionaryContent] = useDictionaryContent();

	const onTransitionStart = () => {
		setIsTransitioning(true);
	};

	const onTransitionEnd = () => {
		setIsTransitioning(false)
	};

	const categorySlug = window.location.pathname?.split("/")[2] || "";
	const queryVariables = {
		slug: postSlug,
		categorySlug,
		skipArticles: postType !== "article",
		skipGalleries: postType !== "gallery",
		skipVideos: postType !== "video",
		includeLanding: false,
		includePosters: false,
		pageSize: 20
	};

	const processFilteredList = () => {
		return getResultPage({
			query: FilteredPostsQuery,
			variables: queryVariables,
			filterResults: post => post.category?.routeSlug === categorySlug && post.routeSlug !== postSlug,
			apolloClient: apollo,
		})
			.then(({ posts }) => {
				if (isNonEmptyArray(posts)) {
					setPosts(posts);
				}
			});
	};

	const renderImage = (post) => {
		let imageData;

		if (isNonEmptyString(post.featuredImage?.url)) {
			imageData = post.featuredImage
		} else if (isNonEmptyString(post.featuredVideo)) {
			return <Video url={post.featuredVideo} noClick />
		} else {
			return <></>;
		}

		return <Image url={imageData.url} alt={imageData.title} aspectRatio="3x2" />;
	};

	useEffect(() => {
		processFilteredList()
			.finally(() => setHasLoaded(true));
	}, []);

	return hasLoaded
		?
		isNonEmptyArray(posts)
			?
			<div data-component="RelatedPosts" className={styles.relatedPosts}>
				<Container>
					<VerticalSpacing>
						<BasePadding>
							<hr className={styles.line}/>
							<Heading type="h2">{dictionaryContent.labelRelatedPosts}</Heading>
						</BasePadding>

						<BasePadding desktop={{ disabled: true }}>
							<div className={styles.posts}>
								<Carousel type="RelatedPosts" cardGap="20" onTransitionStart={onTransitionStart} onTransitionEnd={onTransitionEnd}>
									{
										posts.map((post, index) => {
											let categorySlug = post.category?.routeSlug;

											return (
												<LocaleLink key={index} to={`/${categorySlug}/${post.routeSlug}`} disabled={isTransitioning}>
													<div className={styles.relatedPost}>
														<div className={styles.imageContainer}>
															{renderImage(post)}
														</div>
														<div className={styles.title}>
															<TextTruncate
																line={2}
																truncateText="…"
																text={post.title}
															/>
														</div>
														{(post.synopsis || post.copy?.json) && <div className={styles.description}>
															<TextTruncate
																line={2}
																truncateText="…"
																text={post.synopsis ? post.synopsis : documentToPlainTextString(post.copy.json)}
															/>
														</div>}
													</div>
												</LocaleLink>
											)
										})
									}
								</Carousel>
							</div>
						</BasePadding>
					</VerticalSpacing>
				</Container>
			</div>
			: <></>
	: <LoadingIndicator/>;
};

export default RelatedPosts;