import React from "react";
import PropTypes from "prop-types";
import { isNonEmptyArray } from "helpers";
import { useDictionaryContent } from "hooks/shell";


const AuthorList = ({ authors }) => {
	const [ dictionaryContent ] = useDictionaryContent();

	const numAuthors = isNonEmptyArray(authors) ? authors.length : 0;

	const formatAuthors = () => {
		if (numAuthors === 1) {
			return authors[0].name;
		} else if (numAuthors === 2) {
			return `${authors[0].name} and ${authors[1].name}`
		} else {
			const mutableAuthors = [...authors];
			const lastAuthor = mutableAuthors.pop();

			return `${mutableAuthors.map(author => author.name).join(', ')}, & ${lastAuthor.name}`
		}
	};

	return numAuthors
		? (
		<div data-component="Post\AuthorList">
			{dictionaryContent.labelWrittenBy} {formatAuthors()}
		</div>
		)
		: "";
};

AuthorList.propTypes = {
	authors: PropTypes.arrayOf(PropTypes.shape({
		name: PropTypes.string
	})).isRequired
};

export default AuthorList;