import React from "react";
import Plyr from "react-plyr";
import PropTypes from "prop-types";
import styles from "./styles.css";

require("./plyr.global.css");

const Video = ({ url, noClick }) => {
	return (
		<div data-component="Video" className={styles.mediaWrapper}>
			<div className={`${styles.videoContainer} ${noClick ? styles.noClick : ""}`}>
			{url.includes('bit') &&
				<Plyr type="video" videoId={url} />
			}
			{!url.includes('bit') &&
				<Plyr type="youtube" videoId={url} />
			}
			</div>
		</div>
	);
};

Video.propTypes = {
	url: PropTypes.string.isRequired
};

export default Video;