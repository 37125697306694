import React, { useEffect, useRef } from "react";
import PropTypes from "prop-types";
import { useHeaderContent } from "hooks/shell";
import { isNonEmptyString, lockBackground } from "helpers";
import CloseButton from "components/CloseButton";
import LocaleLink from "components/LocaleLink";
import LanguageFilter from "components/LanguageFilter/LanguageFilter";

import styles from "./styles.css";


const Navigation = ({ isOpen, onClose }) => {
	const [ content ] = useHeaderContent();
	const { categoriesCollection, linksCollection } = content || {};
	const navRef = useRef(null);
	const navContentRef = useRef(null);

	useEffect(() => lockBackground(isOpen, navRef.current), [isOpen, navRef]);

	const handleClose = () => {
		const scrollToTop = () => navContentRef.current.offsetParent.scrollTop = 0;

		scrollToTop();
		onClose();
	};

	return (
		<nav className={isOpen ? styles.openNav : ""}>
			<div className={styles.nav} ref={navRef}>
				<div className={styles.closeButtonContainer}>
					<CloseButton onClick={handleClose} />
				</div>
				<div className={styles.navContent} ref={navContentRef}>
					<ul>
						{categoriesCollection.items.map((link, index) => {
							let formattedCount = ("0" + (index + 1)).slice(-2);

							return isNonEmptyString(link?.name)
								?
								(
								<li className={`${styles.link} ${styles.internalLink}`} key={index}>
									<div className={styles.count}>{formattedCount}</div>
									<LocaleLink to={`/${link.routeSlug}`} onClick={handleClose}>{link.name}</LocaleLink>
								</li>
								)
								: "";
						})}
						{linksCollection.items.map((link, index) => {
							return (
								<li className={`${styles.link} ${styles.externalLink}`} key={index} onClick={handleClose}>
									{(link?.icon?.url && link?.icon?.url !== "") ?
										<img className={styles.linkIcon} src={link.icon.url} alt={link.icon.altTextIcon}/>
										:
										<div className={styles.placeholder}/>
									}
									{link.targetRoute && <a href={link.targetRoute} target={link.openInNewTabWindow ? "_blank" : "_self"}>{link.labelLink}</a>}
									{link.routeSlug && <LocaleLink to={`/${link.routeSlug}`}>{link.labelLink}</LocaleLink>}
								</li>
							)
						})}
					</ul>
					<div className={styles.languageFilterContainer}>
						<LanguageFilter/>
					</div>
				</div>
			</div>
			{isOpen && <div className={styles.overlay} onClick={handleClose} />}
		</nav>
	)
};

Navigation.propTypes = {
	isOpen: PropTypes.bool,
	onClose: PropTypes.func,
	content: PropTypes.object
};

export default Navigation;





