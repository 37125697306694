import React, { useEffect } from "react";
import { Location } from "@reach/router";
import PropTypes from "prop-types";
import { useHeaderContent } from "hooks/shell";
import { useGlobalState, keyCodes, communityRouteSlugs, cultureRouteSlugs, footballRouteSlugs } from "helpers";
import HamburgerHelper from "components/SiteHeader/HamburgerHelper";
import LocaleLink from "components/LocaleLink";
import Navigation from "components/SiteHeader/Navigation";

import styles from "./styles.css";

const SiteHeader = ({ isHomepage }) => {
	const [ headerTitle, setHeaderTitle ] = useGlobalState("headerTitle");
	const [ content ] = useHeaderContent();
	const [navOpen, setNavOpen] = useGlobalState("navOpen");
	const [selectedTheme, setSelectedTheme] = useGlobalState("selectedTheme");
	const [isGeneral] = useGlobalState("isGeneral");
	const [ is404 ] = useGlobalState("is404");

	const openNav = () => {
		setNavOpen(true);
	};

	const closeNav = () => {
		setNavOpen(false);
	};

	const escFunction = (event) => {
		if (event.keyCode === keyCodes.esc) {
			setNavOpen(false);
		}
	};

	useEffect(() => {
		window.addEventListener("keyup", escFunction);
		return () => window.removeEventListener("keyup", escFunction);
	}, []);

	if (!content) {
		return "";
	}

	return (
		<Location>
			{({ location }) => {
				const path = location.pathname?.split("/")[2] || "";
				const culture = cultureRouteSlugs.some(string => path.includes(string));
				const football = footballRouteSlugs.some(string => path.includes(string));
				const community = communityRouteSlugs.some(string => path.includes(string));
				const showTitle = !(is404 || isGeneral);
				let title = headerTitle || path;
				setHeaderTitle(title);

				if (football) {
					setSelectedTheme("football");
				} else if (community) {
					setSelectedTheme("community");
				} else if (culture) {
					setSelectedTheme("culture");
				} else {
					setSelectedTheme(null);
				}

				if (football || community || culture) {
					title = <LocaleLink to={`/${path}`}>{title}</LocaleLink>;
				}

				return (
					<>
					<div data-component="SiteHeader" className={styles.siteHeader}>
						<div className={`${styles.siteHeaderContainer} ${(isHomepage || is404) ? styles.transparentOverlay : ""} ${selectedTheme in styles ? styles[selectedTheme] : ""} ${isGeneral ? styles.darkGrey : ""}`}>
							<LocaleLink to="/" className={styles.logo}><img className={styles.image} src={content.logo.url} alt={content.logo.altTextLogo} /></LocaleLink>
							{showTitle && <span className={styles.title}>{title}</span>}
							<HamburgerHelper color={(isHomepage || is404 || isGeneral) ? "white" : ""} onClick={() => openNav()}/>
						</div>
					</div>
					<Navigation isOpen={navOpen} onClose={() => closeNav()}/>
					</>
				)
			}}
		</Location>
	)
};

SiteHeader.propTypes = {
	isHomepage: PropTypes.bool
};

export default SiteHeader;