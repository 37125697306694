import { useGlobalState } from "helpers";

export const useSelectedLanguage = () => {
	const [ selectedLanguage, setState ] = useGlobalState("selectedLanguage");

	const setSelectedLanguage = languageId => {
		setState(languageId);
	};

	return [ selectedLanguage, setSelectedLanguage ];
};

export const useDictionaryContent = () => {
	const [ shell, setState ] = useGlobalState("shell");

	const setDictionary = content => {
		setState({
			...shell,
			dictionary: content
		});
	};

	return [shell.dictionary, setDictionary];
};

export const useHeaderContent = () => {
	const [ shell, setState ] = useGlobalState("shell");

	const setHeader = content => {
		setState({
			...shell,
			header: content
		});
	};

	return [shell.header, setHeader];
};

export const useFooterContent = () => {
	const [ shell, setState ] = useGlobalState("shell");

	const setFooter = content => {
		setState({
			...shell,
			footer: content
		});
	};

	return [shell.footer, setFooter];
};

export const useCookieContent = () => {
	const [ shell, setState ] = useGlobalState("shell");

	const setCookie = content => {
		setState({
			...shell,
			cookiePolicy: content
		});
	};

	return [shell.cookiePolicy, setCookie];
};

export const useErrorMessageContent = () => {
	const [ shell, setState ] = useGlobalState("shell");

	const setErrorMessage = content => {
		setState({
			...shell,
			errorMessage: content
		});
	};

	return [shell.errorMessage, setErrorMessage];
};

export const useShellContent = () => {
	const [ shell, updateShell ] = useGlobalState("shell");

	const setShell = content => {
		updateShell(content)
	};

	return [ shell, setShell ];
};