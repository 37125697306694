import gql from "graphql-tag";

const {
	REACT_APP_PREVIEW_API: PREVIEW_API
} = process.env;

export const ArticleCard = gql`
	query ArticleCard($locale: String!, $id: String!) {
		article(preview: ${PREVIEW_API === "true"}, locale: $locale, id: $id) {
			title
			routeSlug
			publishedDate
			category {
				name
				routeSlug
			}
			featuredImage {
				... on Asset {
					url
				}
			}
			featuredInsetImage {
				... on Asset {
					url
				}
			}
			subtitle
			synopsis
			copy {
				json
			}
			excludeFromFeed
		}
	}
`;