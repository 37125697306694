import React from "react";
import PropTypes from "prop-types";
import Div100VH from "react-div-100vh";
import { isNonEmptyString } from "helpers";
import BasePadding from "components/BasePadding";
import Cta from "components/Cta";
import Heading from "components/Heading";
import Image from "components/Image";
import LocaleLink from "components/LocaleLink";
import { useDictionaryContent } from "hooks/shell";

import Arrow from "globals/images/arrow.svg";
import ExternalIcon from "globals/images/external-link-icon.svg"
import styles from "./styles.css";


const Cover = ({ title, titleLine2, route, targetUrl, linkLabel, coverImages, is404, coverImage, isShop }) => {
	const isFootball = ["football", "futebol", "futbol"].includes(route);
	const isCommunity = ["community", "comunidade", "comunidad"].includes(route);
	const isCulture = ["culture", "cultura"].includes(route);

	const [ dictionaryContent ] = useDictionaryContent();

	const desktopImage = isNonEmptyString(coverImages?.desktop?.url) ? coverImages?.desktop : coverImages?.featured;
	const mobileImage = isNonEmptyString(coverImages?.mobile?.url) ? coverImages?.mobile : desktopImage;

	const crunchGradientClasses = () => {
		const footballTheme = isFootball ? styles.football : "";
		const communityTheme = isCommunity ? styles.community : "";
		const cultureTheme = isCulture ? styles.culture : "";
		const shopTheme = isShop ? styles.shop : "";
		const notFoundTheme = is404 ? styles.notFound : "";

		return `${styles.gradientOverlay} ${footballTheme} ${communityTheme} ${cultureTheme} ${shopTheme} ${notFoundTheme}`
	};

	const renderLinkedContent = () => {
		return (
			<>
				<Heading type="h1" color="White" hasShadow>{title}</Heading>
				{titleLine2 && <Heading type="h1" color="White" hasShadow>{titleLine2}</Heading>}
			</>
		);
	};

	return (
		<Div100VH data-component="Cover" className={styles.cover} >
			{(desktopImage || coverImage) && <Image useBackgroundImage url={(is404 ? coverImage.url : desktopImage.url)} altText={(is404 ? coverImage.title : desktopImage.title)} className={styles.desktopBackground}/>}
			{(mobileImage || coverImage) && <Image useBackgroundImage url={(is404 ? coverImage.url : mobileImage.url)} altText={(is404 ? coverImage.title : mobileImage.title)} className={styles.mobileBackground}/>}

			<div className={crunchGradientClasses()} />

			<BasePadding desktop={{ fullHeight: true }} mobile={{ fullHeight: true }}>
				<div className={styles.body}>
					<div className={styles.headingWrapper}>
						{isNonEmptyString(title) && isNonEmptyString(route) &&
						<LocaleLink to={`/${route}`}>
							{renderLinkedContent()}
							<div className={styles.arrowWrapper}>
								<Arrow />
								{linkLabel ? linkLabel : (dictionaryContent?.labelExplore || "Explore")}
							</div>
						</LocaleLink>
						}

						{isNonEmptyString(targetUrl) &&
							<Cta type="Button">
								<div className={styles.wrapper}>
									<a href={targetUrl} target="_blank" rel="noopener noreferrer">
										{renderLinkedContent()}
										<button className={styles.shopButton}>{linkLabel || "Shop"} <ExternalIcon /></button>
									</a>
								</div>
							</Cta>
						}
					</div>
				</div>
			</BasePadding>
		</Div100VH>
	);
};

Cover.propTypes = {
	title: PropTypes.string,
	route: PropTypes.string,
	coverImages: PropTypes.shape({
		desktop: PropTypes.shape({
			url: PropTypes.string,
			title: PropTypes.string
		}),
		mobile: PropTypes.shape({
			url: PropTypes.string,
			title: PropTypes.string
		}),
		featured: PropTypes.shape({
			url: PropTypes.string,
			title: PropTypes.string
		})
	})
};

export default Cover;


