import gql from "graphql-tag";

export const GeneralQuery = gql`
	query GeneralQuery($locale: String!, $routeSlug: String!) {
		generalCollection(locale: $locale, where: { routeSlug: $routeSlug }, limit: 1) {
			items {
				routeSlug
				content {
					json
				}
			}
		}
	}
`;