import React from "react";
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';

export const isNonEmptyString = value => typeof value === "string" && value.length > 0;
export const isString = value => typeof value === "string";
export const isNumber = value => typeof value === "number";
export const isBool = value => typeof value === "boolean";
export const isNonEmptyArray = value => Array.isArray(value) && value.length > 0;
export const isFunction = value => typeof value === "function";
export const isObject = object => object && typeof object === "object";
export const isObjectWithKey = (object, key) => isObject(object) && key in object;

export const getDeepObjectValue = (object, keyPath) => {
	const path = keyPath.split(".");

	const sentinelKey = "__END_OF_THE_LINE__";
	const value = isObject(object)
		? path.reduce((accumulator, key) => (isObjectWithKey(accumulator, key) ? accumulator[key] : sentinelKey), object)
		: undefined;

	return value === sentinelKey ? undefined : value;
};

export const getFirstCollectionItem = collection => isNonEmptyArray(collection.items) ? collection.items[0] : undefined;

export const lockBackground = (shouldLock, target) => shouldLock ? disableBodyScroll(target) : enableBodyScroll(target);

export const debounce = (func, wait, immediate) => {
	let timeout;

	return function () {
		const context = this, args = arguments;
		const later = function () {
			timeout = null;
			if (!immediate) {
				func.apply(context, args);
			}
		};
		const callNow = immediate && !timeout;
		clearTimeout(timeout);
		timeout = setTimeout(later, wait);
		if (callNow) {
			func.apply(context, args);
		}
	};
};

export const keyCodes = {
	esc: 27
};

export const removeFalsyValues = array => isNonEmptyArray(array) ? array.filter(Boolean) : array;

export const shuffleArray = a => {
	if (isNonEmptyArray(a)) {
		for (let i = a.length - 1; i > 0; i--) {
			const j = Math.floor(Math.random() * (i + 1));
			[a[i], a[j]] = [a[j], a[i]];
		}
	}

	return a;
};

export const scrollWindowToTop = () => window.scrollTo(0, 0);