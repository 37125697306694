import { createGlobalState } from "react-hooks-global-state";

const initialState = {
  navOpen: false,
  scrollY: null,
  selectedLanguage: null,
  selectedTheme: null,
  isGeneral: false,
  is404: false,
  shell: {
    dictionary: null,
    header: null,
    footer: null,
  },
  feedFilters: {},
  headerTitle: "",
  pageTitle: "Neymar Jr."
};

const { GlobalStateProvider, useGlobalState } = createGlobalState(initialState);

export {
  GlobalStateProvider,
  useGlobalState
};