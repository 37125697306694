import React, { useEffect } from "react";
import Div100VH from "react-div-100vh";
import { isNonEmptyArray, isNonEmptyString, useGlobalState } from "helpers";
import { useErrorMessageContent } from "hooks/shell";
import Cover from "components/Cover";
import LoadingIndicator from "components/LoadingIndicator";

import Swirls from "globals/images/graphic-circles.svg"
import styles from "./styles.css";

const NotFound = () => {

	const [ , setPageTitle ] = useGlobalState("pageTitle");
	const [, setIs404 ] = useGlobalState("is404");
	useEffect(() => {
		setIs404(true);
		return () => setIs404(false);
	}, [setIs404])

	const [ content ] = useErrorMessageContent();

	if (!content) {
		return ""
	}

	setPageTitle("404 | Neymar Jr.");

	return (
		<div data-component="Containers\NotFound" className={styles.notFound}>
			<Div100VH className={styles.body}>
				<div className={styles.coverWrapper}>
					<Cover title={content.errorMessageLine1} titleLine2={content.errorMessageLine2} linkLabel={content.labelLink} route="/" coverImage={content.backgroundImage} is404/>
				</div>

				<div className={styles.swirlWrapper}>
					<Swirls />
				</div>
			</Div100VH>
		</div>
	);
};

export default NotFound;