import React from "react";
import PropTypes from "prop-types";
import moment from "moment";
import { ArticleCard } from "schema";
import { isNonEmptyString } from "helpers";
import { useDictionaryContent } from "hooks/shell";
import { documentToPlainTextString } from "@contentful/rich-text-plain-text-renderer";

import Arrow from "globals/images/arrow.svg";
import ConnectedComponent from "components/ConnectedComponent";
import Container from "components/Container";
import Cta from "components/Cta";
import BasePadding from "components/BasePadding";
import Eyebrow from "components/Eyebrow";
import Heading from "components/Heading";
import Image from "components/Image";
import LocaleLink from "components/LocaleLink";
import Synopsis from "components/Synopsis";
import VerticalSpacing from "components/VerticalSpacing";
import Video from "components/Video";

import styles from "./styles.css";


const Card = props => {

	const {
		routeSlug,
		featuredImage,
		featuredVideo,
		featuredInsetImage,
		publishedDate,
		synopsis,
		copy,
		title,
		subtitle,
		category
	} = props;

	const [ content ] = useDictionaryContent();

	const subcategoryKey = `subcategory${category.name}Topic`;
	const subcategoryLabel = subcategoryKey in props ? props[subcategoryKey] : "";
	const formattedPublishedDate = moment(publishedDate).format("ll");
	const hasVideo = isNonEmptyString(featuredVideo);

	return (
		<div data-component="ArticleFeedCardFeatured" className={styles.articleFeedCardFeatured}>
			<Container>
				<VerticalSpacing>
					<BasePadding mobile={{ disabled: true }}>
						<div className={styles.flexContainer}>
							<div className={styles.image}>
								<div className={featuredInsetImage ? styles.innerImage : ""}>
									<LocaleLink to={`/${category.routeSlug}/${routeSlug}`}>
										{hasVideo
											? <Video url={featuredVideo} />
											: <Image url={featuredImage.url} altText={featuredImage.title} aspectRatio="16x9" />
										}
									</LocaleLink>
									{(featuredInsetImage && !hasVideo) &&
										<div className={styles.secondaryImageDesktop}>
											<LocaleLink to={`/${category.routeSlug}/${routeSlug}`}>
												<Image url={featuredInsetImage.url} altText={featuredInsetImage.title} aspectRatio="1x1" />
											</LocaleLink>
										</div>
									}
								</div>
							</div>
							<div className={styles.rightContent}>
								{subcategoryLabel &&
									<div className={styles.eyebrow}>
										<Eyebrow>{subcategoryLabel}</Eyebrow>
									</div>
								}
								<div className={styles.content}>
									<div className={styles.heading}>
										<LocaleLink to={`/${category.routeSlug}/${routeSlug}`}>
											<Heading type="h2">{title}</Heading>
										</LocaleLink>
									</div>
									<p className={styles.subtitle}>{subtitle}</p>
									{(featuredInsetImage && !hasVideo) &&
										<div className={styles.secondaryImageMobile}>
											<Image url={featuredInsetImage.url} altText={featuredInsetImage.title} aspectRatio="1x1" />
										</div>
									}
									{(synopsis || copy) &&
										<div className={styles.synopsis}>
											<Synopsis text={synopsis ? synopsis : documentToPlainTextString(copy.json)} isTruncated={!synopsis} />
										</div>
									}
									<div className={styles.date}>{formattedPublishedDate}</div>
									<Cta type="Link">
										<LocaleLink to={`/${category.routeSlug}/${routeSlug}`}>
											<Arrow />{hasVideo ? content?.labelWatchMore : content?.labelReadMore}
										</LocaleLink>
									</Cta>
									<hr className={styles.line} />
								</div>
							</div>
						</div>
					</BasePadding>
				</VerticalSpacing>
			</Container>
		</div>
	)
};

const ArticleFeedCardFeatured = props => {
	const {
		content,
		fetchQuery
	} = props;

	if (content) {
		// If we already have the content for this card (parent query took care of it).
		return (
			<Card {...props.content} />
		)
	} else if (fetchQuery) {
		// If we don't know the content of the card, but have an ID to query it.
		const success = props => {
			return <Card {...props.article} />;
		};
		return <ConnectedComponent query={ArticleCard} variables={{ id: fetchQuery }} success={success} />;
	} else {
		return <></>;
	}
};


ArticleFeedCardFeatured.propTypes = {
	content: PropTypes.shape({
		routeSlug: PropTypes.string,
		copy: PropTypes.object,
		featuredImage: PropTypes.object,
		featuredVideo: PropTypes.string,
		featuredInsetImage: PropTypes.object,
		publishedDate: PropTypes.string,
		synopsis: PropTypes.string,
		title: PropTypes.string,
		subtitle: PropTypes.string,
		category: PropTypes.object
	})
};

export default ArticleFeedCardFeatured;