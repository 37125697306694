import gql from "graphql-tag";

export const ContactModule = gql`
    query ContactModule($locale: String!, ) {
        moduleContactCollection(locale: $locale, limit: 1) {
			items {
                title
                description
                labelConfirmation
                labelConfirmationSubtitle
                labelEmail
                labelEmailRequired
                labelGenericError
                labelInvalidEmail
                labelMessage
                labelMessageRequired
                labelName
                labelNameRequired
                labelSubmit
            }
        }
    }
`;
