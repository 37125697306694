import React from "react";
import PropTypes from "prop-types";
import { Form, withFormik } from "formik";
import * as Yup from "yup";
import { isFunction, isNonEmptyArray } from "helpers";

import Cta from "components/Cta";
import Email from "components/Forms/Elements/Email"
import TextArea from "components/Forms/Elements/TextArea"
import TextInput from "components/Forms/Elements/TextInput"

import styles from "./styles.css";


const Contact = ({ isSubmitting, errors, labels: { labelEmail, labelMessage, labelName, labelSubmit } }) => {
	const isDisabled = isSubmitting || isNonEmptyArray(Object.keys(errors));

	return (
		<div data-component="Forms\Contact">
			<Form>
				<div className={`${styles.formRow} ${styles.splitDesktop}`}>
					<div className={styles.formField}>
						<TextInput id="name" name="name" label={labelName} required hasError={errors.name}/>
					</div>

					<div className={styles.formField}>
						<Email id="email" name="email" label={labelEmail} required hasError={errors.email}/>
					</div>
				</div>

				<div className={`${styles.formRow} ${styles.textareaWrapper}`}>
					<TextArea id="message" name="message" label={labelMessage} required hasError={errors.message}/>
				</div>

				<div className={`${styles.formRow}`}>
					<Cta color="Black">
						<button type="submit" className={styles.submit} disabled={isDisabled}>{labelSubmit}</button>
					</Cta>
				</div>
			</Form>
		</div>
	);
};

const formikConfig = {
	enableReinitialize: true,
	initialValues: () => ({ name: "", email: "", message: "" }),
	handleSubmit: (values, formikBag) => {
		const { setSubmitting, props: { onSubmit, onSuccess } } = formikBag;
		const submissionData = {
			name: values.name,
			email: values.email,
			message: values.message
		};
		const url = process.env.REACT_APP_CONTACT_ENDPOINT;

		setSubmitting(true);

		fetch(url, {
			method: "POST",
			body: JSON.stringify(submissionData),
			headers: { "Content-Type": "application/json" }
		})
			.then((result) => {

				if (!result.ok) {
					throw "Something went wrong with the request"
				}

				if (isFunction(onSuccess)) {
					onSuccess();
				}
			})
			.catch((x) => {
				console.log("Failure %o", x);
			})
			.finally(() => {
				setSubmitting(false);
			});

		if (isFunction(onSubmit)) {
			onSubmit();
		}

		return true;
	},
	validationSchema: ({ labels }) => Yup.object().shape({
		email: Yup.string()
			.email(labels.labelInvalidEmail)
			.required(labels.labelEmailRequired),
		name: Yup.string()
			.required(labels.labelNameRequired),
		message: Yup.string()
			.required(labels.labelMessageRequired),
	})
};

Contact.propTypes = {
	labelConfirmation: PropTypes.string,
	labelEmail: PropTypes.string,
	labelEmailRequired: PropTypes.string,
	labelGenericError: PropTypes.string,
	labelInvalidEmail: PropTypes.string,
	labelMessage: PropTypes.string,
	labelMessageRequired: PropTypes.string,
	labelName: PropTypes.string,
	labelNameRequired: PropTypes.string,
	labelSubmit: PropTypes.string,
	isSubmitting: PropTypes.bool,
	errors: PropTypes.object
};

export default withFormik(formikConfig)(Contact);