import React, { useState, useEffect } from "react";
import moment from "moment";

import { useGlobalState } from "helpers";
import { InstagramCard } from "schema/fragments/Instagram";
import { useFetch } from "helpers";

import Carousel from "components/Carousel";
import ConnectedComponent from "components/ConnectedComponent";
import Cta from "components/Cta";
import Eyebrow from "components/Eyebrow";
import Heading from "components/Heading";
import LoadingIndicator from "components/LoadingIndicator";
import VerticalSpacing from "components/VerticalSpacing";

import Info from "globals/images/info-icon.svg";
import styles from "./styles.css";

const Card = props => {
	const {
		username,
		eyebrow,
		labelCallToAction,
		targetRoute,
		apiToken,
		userId,
	} = props;

	const [ currentItem, setCurrentItem ] = useState(null);
	const [ selectedTheme ] = useGlobalState("selectedTheme");

	const [data, loading] = useFetch(`https://api.instagram.com/v1/users/${userId}/media/recent/?access_token=${apiToken}`);

	return data ? (
		<VerticalSpacing>
			<section className={`${styles.instagram} ${selectedTheme in styles ? styles[selectedTheme] : ""}`}>
				<div className={styles.header}>
					<span className={styles.eyebrow}>
						<Eyebrow>{eyebrow}</Eyebrow>
					</span>
					<div className={styles.instagramHeading}>
						<Heading type="h2">{`@${username}`}</Heading>
						<Cta>
							<a href={targetRoute} target="_blank" rel="noopener noreferrer">{labelCallToAction}</a>
						</Cta>
					</div>
				</div>
				{loading ?
					<LoadingIndicator />
					:
					<Carousel type="Instagram" cardGap="20">
						{data.filter(post => (post.type === "image" || post.type === "carousel")).map((post, index) => (
							<div className={styles.post} key={index}>
								<img src={post.images.standard_resolution.url} alt={post?.caption?.text} />
								{post.caption &&
									<>
										<div className={`${styles.caption} ${currentItem === index ? styles.forceShow : ""}`}>
											<strong>{post?.caption?.from?.username}</strong>
											<p>{post?.caption?.text}</p>
											<span>{moment.unix(post?.caption?.created_time).format("ll")}</span>
										</div>
										<button className={styles.info} onClick={() => setCurrentItem(currentItem !== index ? index : null)}><Info /></button>
									</>
								}
							</div>
						))}
					</Carousel>
				}
				<div className={styles.mobileCta}>
					<Cta>
						<a href={targetRoute} target="_blank" rel="noopener noreferrer">{labelCallToAction}</a>
					</Cta>
				</div>
			</section>
		</VerticalSpacing>
	)
	: "";	
}

const Instagram = ({ fetchQuery }) => {
	const success = props => (
		<Card {...props.moduleInstagram} />
	)
	return <ConnectedComponent query={InstagramCard} variables={{ id: fetchQuery }} success={success}/>
}

export default Instagram;