import 'promise-polyfill/src/polyfill';
import 'core-js/es6/array';
import 'core-js/es6/object';
import 'whatwg-fetch';
import includes from 'array-includes';

if (!Object.prototype.includes) {
  Object.defineProperty(Object.prototype, 'includes', {
    value: function value(needle) {
      return includes(this, needle);
    }
  });
}