import React from "react";
import PropTypes from "prop-types";

import styles from "./styles.css";

const Cta = ({ icon, color, type, hasBorder, children, accessibilityLabel }) => {
	const crunchClasses = () => {
		const ctaType = type ? type : "Button";
		const Icon = icon ? styles[`icon${icon}`] : "";
		const Color = color ? styles[`color${color}`] : "";
		const Type = styles[`type${ctaType}`];
		const Border = hasBorder ? styles.border : "";

		return `${Icon} ${Color} ${Type} ${Border}`;
	};

	const ariaLabel = (children.props.href || children.props.url) + " " + accessibilityLabel;
	const isBlankTarget = children.props && children.props.target && children.props.target === "_blank";

	return (
		<div data-component="Cta" className={`${styles.cta} ${crunchClasses()}`}>
			{isBlankTarget
				? React.cloneElement(children, { "aria-label": ariaLabel })
				: children
			}
		</div>
	);
};

Cta.propTypes = {
	icon: PropTypes.string,
	color: PropTypes.string,
	type: PropTypes.string,
	accessibilityLabel: PropTypes.string,
	children: PropTypes.node.isRequired
};

export default Cta;