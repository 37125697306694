import React from "react";
import PropTypes from "prop-types";
import { isFunction } from "helpers";

import styles from "./styles.css";


const DotCounter = ({ currentItem, totalItems, color, onClick }) => {

	const handleClick = index => {
		if (isFunction(onClick)) {
			onClick(index);
		}
	};

	const crunchClasses = i => {
		const isActive = currentItem === i ? styles.active : "";
		return `${styles.dot} ${isActive}`;
	};

	let offset = 0;

	if (currentItem > totalItems - 3) {
		offset = (totalItems - 5) * -22
	} else if(currentItem > 2) {
		offset = (currentItem - 2) * -22
	}

	return (
		<div data-component="DotCounter" className={`${styles.dotCounter} ${color in styles ? styles[color] : ""}`} >
			<div className={styles.window} style={{ transform: `translateX(${offset}px)`}}>
				{Array(totalItems).fill().map((_, i) => (
					<div key={i} className={crunchClasses(i)} onClick={() => handleClick(i)}/>
				))}
			</div>
		</div>
	);
};

DotCounter.propTypes = {
	currentItem: PropTypes.number.isRequired,
	totalItems: PropTypes.number.isRequired,
	color: PropTypes.oneOf(["light", "dark"])
};

export default DotCounter;