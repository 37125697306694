import gql from "graphql-tag";

const {
	REACT_APP_PREVIEW_API: PREVIEW_API
} = process.env;

export const LandingPageQuery = gql`
    query LandingPageQuery($locale: String!) {
        shellCoverCollection (preview: ${PREVIEW_API === "true"}, limit: 1, locale: $locale) {
            items {
                labelNikeShop
                nikeHeadline
                targetRoute
                nikeCoverImage {
                    url
                    title
                }
                nikeCoverImageMobile {
                    url
                    title
                }
                nikeSwooshLogo {
                    url
                    title
                }
                categoriesCollection (limit: 20) {
                    items {
                        routeSlug
                        name
                        sys {
                            id
                        }
                    }
                }
            }
        }
        landingCollection(preview: ${PREVIEW_API === "true"}, locale: $locale) {
            items {
                coverStory {
                    ...on Article {
                        sys {
                            id
                        }
                    }
                    ...on Gallery {
                        sys {
                            id
                        }
                    }
                    ...on Video {
                        sys {
                            id
                        }
                    }
                }
                __typename
                routeSlug
            }
        }
    }
`;

export const LandingPostsQuery = gql`
    query LandingPostsQuery($locale: String!, $ids: [String]!) {
        articleCollection(preview: ${PREVIEW_API === "true"}, locale: $locale, where: { sys: { id_in: $ids} }) {
            items {
                title,
                featuredImage {
                    url
                    title
                }
                coverImageDesktop {
                    url
                    title
                }
                coverImageMobile {
                    url
                    title
                }
                sys {
                    id
                }
            }
        }
        galleryCollection(preview: ${PREVIEW_API === "true"}, locale: $locale, where: { sys: { id_in: $ids} }) {
            items {
                title
                featuredImage {
                    url,
                    title
                }
                coverImageDesktop {
                    url
                    title
                }
                coverImageMobile {
                    url
                    title
                }
                galleryCollection(limit: 1) {
                    items {
                        url
                        title
                    }
                }
                sys {
                    id
                }
            }
        }
        videoCollection(preview: ${PREVIEW_API === "true"}, locale: $locale, where: { sys: { id_in: $ids} }) {
            items {
                title
                coverImageDesktop {
                    url
                    title
                }
                coverImageMobile {
                    url
                    title
                }
                sys {
                    id
                }
            }
        }
    }
`;

const PostsArticleCollectionFragment = gql`
    fragment PostsArticleCollectionFragment on ArticleCollection {
        total
        items {
            title
            subtitle
            publishedDate
            routeSlug
            synopsis
            featuredImage {
                url
                title
            }
            featuredInsetImage {
                url
                title
            }
            category {
                name
                routeSlug
            }
            subcategoryCommunityTopic
            subcategoryCultureBrands
            subcategoryCultureTopic
            subcategoryFootballCompetition
            subcategoryFootballTopic
            subcategoryFootballTeam
            copy {
                json
            }
            excludeFromFeed
            __typename
            sys {
                id
            }
        }
    }
`;

const PostsGalleryCollectionFragment = gql`
    fragment PostsGalleryCollectionFragment on GalleryCollection {
        total
        items {
            title
            routeSlug
            publishedDate
            category {
                name
                routeSlug
            }
            subcategoryCommunityTopic,
            subcategoryCultureTopic,
            subcategoryCultureBrands,
            subcategoryFootballTopic,
            subcategoryFootballTeam,
            subcategoryFootballCompetition,
            featuredImage {
                url
                title
            }
            description
            excludeFromFeed
            galleryCollection (limit: 100) {
                items {
                    title
                    description
                    url
                    width
                    height
                }
            }
            __typename
            sys {
                id
            }
        }
    }
`;

const PostsVideoCollectionFragment = gql`
    fragment PostsVideoCollectionFragment on VideoCollection {
        total
        items {
            title
            subtitle
            publishedDate
            routeSlug
            synopsis
            featuredVideo
            coverImageDesktop {
                title
                url
            }
            coverImageMobile {
                title
                url
            }
            category {
                name
                routeSlug
            }
            subcategoryCommunityTopic,
            subcategoryCultureTopic,
            subcategoryCultureBrands,
            subcategoryFootballTopic,
            subcategoryFootballTeam,
            subcategoryFootballCompetition,
            copy {
                json
            }
            excludeFromFeed
            __typename
            sys {
                id
            }
        }
    }
`;

export const FilteredPostsQuery = gql`
    query FilteredPostsQuery(
        $locale: String!,
        $articleOffset: Int!,
        $galleryOffset: Int!,
        $videoOffset: Int!,
        $includeLanding: Boolean!,
        $includePosters: Boolean!,
        $skipArticles: Boolean!,
        $skipGalleries: Boolean!,
        $skipVideos: Boolean!,
        $startDate: DateTime,
        $endDate: DateTime,
        $categoryRouteSlug: String,
        $subcategoryCommunityTopic: String,
        $subcategoryCultureBrands: String,
        $subcategoryCultureTopic: String,
        $subcategoryFootballCompetition: String,
        $subcategoryFootballTeam: String,
        $subcategoryFootballTopic: String,
    ) {
        landingCollection(preview: ${PREVIEW_API === "true"}, locale: $locale, where: {routeSlug: $categoryRouteSlug}, limit: 1) @include(if: $includeLanding) {
            items {
                coverStory {
                    ... on Article {
                        routeSlug
                        title
                    }
                    ... on Gallery {
                        routeSlug
                        title
                    }
                    ... on Video {
                        routeSlug
                        title
                    }
                }
                pinnedContentCollection {
                    items {
                        __typename
                        ... on ModuleInstagram {
                            sys {
                                id
                            }
                        }
                        ... on Article {
                            sys {
                                id
                            }
                        }
                        ... on Video {
                            sys {
                                id
                            }
                        }
                        ... on Gallery {
                            sys {
                                id
                            }
                        }
                        ... on ModuleProductShowcase {
                            sys {
                                id
                            }
                        }
                        ... on ModuleStoryTelling {
                            sys {
                                id
                            }
                        }
                    }
                }
            }
        }
        moduleAlbumPosterCollection(preview: ${PREVIEW_API === "true"}, locale: $locale) @include(if: $includePosters) {
            items {
                quote
                theme
                image {
                    url
                    title
                }
                icon {
                    url
                    title
                }
                sys {
                    id
                }
            }
        }
        articleCollection (
            preview: ${PREVIEW_API === "true"}
            locale: $locale 
            skip: $articleOffset
            limit: 20
            order: [publishedDate_DESC]
            where: {
                excludeFromFeed_not: "Yes"
                AND: [{publishedDate_gt: $startDate}, {publishedDate_lte: $endDate}]
                subcategoryCommunityTopic: $subcategoryCommunityTopic
                subcategoryCultureBrands: $subcategoryCultureBrands
                subcategoryCultureTopic: $subcategoryCultureTopic
                subcategoryFootballCompetition: $subcategoryFootballCompetition
                subcategoryFootballTeam: $subcategoryFootballTeam
                subcategoryFootballTopic: $subcategoryFootballTopic
            }
        ) @skip (if: $skipArticles) {
            ...PostsArticleCollectionFragment
        }
        galleryCollection (
            preview: ${PREVIEW_API === "true"}
            locale: $locale
            skip: $galleryOffset
            limit: 20
            order: [publishedDate_DESC]
            where: {
                excludeFromFeed_not: "Yes"
                AND: [{publishedDate_gt: $startDate}, {publishedDate_lte: $endDate}]
                subcategoryCommunityTopic: $subcategoryCommunityTopic
                subcategoryCultureBrands: $subcategoryCultureBrands
                subcategoryCultureTopic: $subcategoryCultureTopic
                subcategoryFootballCompetition: $subcategoryFootballCompetition
                subcategoryFootballTeam: $subcategoryFootballTeam
                subcategoryFootballTopic: $subcategoryFootballTopic
            }
        ) @skip (if: $skipGalleries) {
            ...PostsGalleryCollectionFragment
        }
        videoCollection(
            preview: ${PREVIEW_API === "true"}
            locale: $locale
            skip: $videoOffset
            limit: 20
            order: [publishedDate_DESC]
            where: {
                excludeFromFeed_not: "Yes"
                AND: [{publishedDate_gt: $startDate}, {publishedDate_lte: $endDate}]
                subcategoryCommunityTopic: $subcategoryCommunityTopic
                subcategoryCultureBrands: $subcategoryCultureBrands
                subcategoryCultureTopic: $subcategoryCultureTopic
                subcategoryFootballCompetition: $subcategoryFootballCompetition
                subcategoryFootballTeam: $subcategoryFootballTeam
                subcategoryFootballTopic: $subcategoryFootballTopic
            }
        ) @skip (if: $skipVideos) {
            ...PostsVideoCollectionFragment
        }
    },
    ${PostsArticleCollectionFragment},
    ${PostsGalleryCollectionFragment}
    ${PostsVideoCollectionFragment}
`;

export const PostSlugValidation = gql`
    query PostSlugValidation($locale: String!, $postSlugs: [String!]) {
        articleCollection(preview: ${PREVIEW_API === "true"}, locale: $locale, where: { routeSlug_in: $postSlugs }) {
            items {
                sys {
                    id
                }
            }
        }
        galleryCollection(preview: ${PREVIEW_API === "true"}, locale: $locale, where: { routeSlug_in: $postSlugs }) {
            items {
                sys {
                    id
                }
            }
        }
        videoCollection(preview: ${PREVIEW_API === "true"}, locale: $locale, where: { routeSlug_in: $postSlugs }) {
            items {
                sys {
                    id
                }
            }
        }
    }
`;

export const GetPostSlugsFromIds = gql`
    query GetPostSlugsFromIds($locale: String!, $postIds: [String!]) {
        articleCollection(preview: ${PREVIEW_API === "true"}, locale: $locale, where: { sys: { id_in: $postIds } }) {
            items {
                routeSlug
                sys {
                    id
                }
            }
        }
        galleryCollection(preview: ${PREVIEW_API === "true"}, locale: $locale, where: { sys: { id_in: $postIds } }) {
            items {
                routeSlug
                sys {
                    id
                }
            }
        }
        videoCollection(preview: ${PREVIEW_API === "true"}, locale: $locale, where: { sys: { id_in: $postIds } }) {
            items {
                routeSlug
                sys {
                    id
                }
            }
        }
    }
`;
