import React from "react";
import PropTypes from "prop-types"
import { isNonEmptyString, isNonEmptyArray } from "helpers";

import styles from "./styles.css";


const PullQuote = ({ size, children }) => {
	return isNonEmptyArray(children) || isNonEmptyString(children)
		? (
		<aside data-component="PullQuote" className={`${styles.pullQuote} ${size in styles ? styles[size] : ""}`}>
			<blockquote className={styles.text}>{children}</blockquote>
		</aside>
		)
		: "";
};

PullQuote.propTypes = {
	children: PropTypes.oneOfType([
		PropTypes.node,
		PropTypes.string
	]),
	size: PropTypes.oneOf(["large", "xl"]),
};

PullQuote.defaultProps = {
	size: "large"
};

export default PullQuote;