import React from "react";

const FooterLink = ({ targetRoute, icon, altText, openInNewTabWindow, label }) => (
	<a data-component="SiteFooter\FooterLink" href={targetRoute} target={openInNewTabWindow ? "_blank" : "_self"}>
		{icon ?
			<img src={icon} alt={altText ? altText : ""}/>
		:
			<>{label}</>
		}
	</a>
);

export default FooterLink;