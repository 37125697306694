import React, { useState, useEffect, useLayoutEffect } from "react";
import { Redirect, Router, Match } from "@reach/router";
import {
	setLocaleId,
	isRouteCodeSupported,
	getValueFromLanguageById,
	DEFAULT_LANGUAGE_ID,
	useGlobalState
} from "helpers";
import { useSelectedLanguage } from "hooks/shell";
import Cookie from "components/Cookie";
import PosterCollection from "containers/PosterCollection";
import Home from "containers/Home";
import Posts from "containers/Posts";
import Post from "containers/Post";
import General from "containers/General";
import NotFound from "containers/NotFound";
import SiteFooter from "components/SiteFooter";
import SiteHeader from "components/SiteHeader";
import Shell from "components/Shell";
import ScrollToTop from "components/ScrollToTop";

export const LocaleHandler = ({ localeRouteCode, uri, location: { pathname }, ...rest }) => {
	const [selectedLocaleId, setSelectedLanguage] = useSelectedLanguage();

	useLayoutEffect(() => {
		if (localeRouteCode !== selectedLocaleId) {
			setLocaleId(localeRouteCode);
			setSelectedLanguage(localeRouteCode);
		}
	});

	if (!isRouteCodeSupported(localeRouteCode)) {
		const defaultLanguageRoute = getValueFromLanguageById("routeCode", DEFAULT_LANGUAGE_ID);
		const defaultLanguagePathname = pathname.replace(uri, `/${defaultLanguageRoute}`);

		return <Redirect from="/" to={defaultLanguagePathname} noThrow/>;
	}

	return <LocaleAwareRouting {...rest} />;
};

const LocaleAwareRouting = ({ isHomepage }) => {
	const [ is404 ] = useGlobalState("is404");
	const [ , setHeaderTitle ] = useGlobalState("headerTitle");
	const onHomepageCoverChange = ({ cover }) => {
		const pageTitle = cover?.pageTitle || cover?.routeSlug;
		setHeaderTitle(pageTitle || "");
	};

	return (
		<>
			<Shell/>
			<SiteHeader isHomepage={isHomepage} />
			<main className={(isHomepage || is404) ? "noTopMargin" : ""}>
				<Router>
					<ScrollToTop path="/">
						<Home path="/" onChange={onHomepageCoverChange} />
						<Posts path="/football" slug="football" category="Football" />
						<Post path="/football/:post"/>
						<Posts path="/futebol" slug="futebol" category="Futebol" />
						<Post path="/futebol/:post"/>
						<Posts path="/futbol" slug="futbol" category="Futbol" setHeaderTitle={() => setHeaderTitle("Fútbol")}/>
						<Post path="/futbol/:post"/>
						<Posts path="/community" slug="community" category="Community" />
						<Post path="/community/:post"/>
						<Posts path="/comunidad" slug="comunidad" category="Comunidad" />
						<Post path="/comunidad/:post"/>
						<Posts path="/comunidade" slug="comunidade" category="Comunidade" />
						<Post path="/comunidade/:post"/>
						<Posts path="/culture" slug="culture" category="Culture" />
						<Post path="/culture/:post"/>
						<Posts path="/cultura" slug="cultura" category="Cultura" />
						<Post path="/cultura/:post"/>
						<PosterCollection path="/poster-collection"/>
						<PosterCollection path="/colecao-de-cartazes"/>
						<PosterCollection path="/coleccion-de-carteles"/>
						<General path="/cookie-policy" />
						<General path="/politica-de-cookies" />
						<General path="/terms" />
						<General path="/termos" />
						<General path="/terminos" />
						<General path="/privacy" />
						<General path="/privacidade" />
						<General path="/intimidad" />
						<NotFound path="/404" />
						<NotFound path="/*" />
					</ScrollToTop>
				</Router>
			</main>
			<Cookie/>
			{(!isHomepage && !is404) && <SiteFooter/>}
		</>
	);
};

const PosterRedirect = ({ target }) => {
	return <Redirect to={target} from="./" noThrow />
};

const Routing = () => {
	const defaultLanguageRoute = getValueFromLanguageById("routeCode", DEFAULT_LANGUAGE_ID);

	return (
		<>
			<Match path="/:localeRouteCode/">
			{({ match }) => {
				return (
					<Router>
						<PosterRedirect path="/share/en/share-poster.htm" target="/en/poster-collection" />
						<PosterRedirect path="/share/es/compartir-cartel.htm" target="/es/coleccion-de-carteles" />
						<PosterRedirect path="/share/pt/compartilhar-cartaz.htm" target="/pt/colecao-de-cartazes" />
						<LocaleHandler isHomepage={!!match} path=":localeRouteCode/*"/>
						<Redirect from="/" to={defaultLanguageRoute} default noThrow/>
					</Router>
				);
			}}
			</Match>
		</>
	);
};

export default Routing;