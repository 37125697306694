import { isNonEmptyString } from "helpers";

export const storeSavedPoster = (id) => {
	const savedPosters = getSavedPosters() || {};

	savedPosters[id] = true;

	localStorage.setItem("savedPosters", JSON.stringify(savedPosters));
};

export const getSavedPosters = () => {
	const savedPosters = localStorage.getItem("savedPosters");

	return isNonEmptyString(savedPosters) ? JSON.parse(savedPosters) : {};
};

export const isPosterCollected = id => {
	const savedPosters = getSavedPosters();

	return savedPosters[id] === true;
};

export const countSavedPosters = () => {
	const savedPosters = getSavedPosters();

	return Object.keys(savedPosters).length;
};