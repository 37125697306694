import React, { useState, useRef, useEffect } from "react";
import PropTypes from "prop-types";
import SwipeableViews from "react-swipeable-views";
import { isFunction, debounce } from "helpers";
import { useDictionaryContent } from "hooks/shell";

import Arrow from "globals/images/carousel-arrow.svg";
import styles from "./styles.css";

const MAX_CONTAINER_SIZE = 1440;
const SIZING_CUTOFF = 768;
const PAGE_SIZE = 1;

const Carousel = ({ children, type, fullBleed, circular, color, cardGap, ignoreResize, startingIndex, changeToIndex, onItemChange, onTransitionStart, onTransitionEnd }) => {
	const containerRef = useRef(null);
	const arrowRef = useRef(null);
	const [, forceUpdate ] = useState(null);
	const [cardsToFit, setCardsToFit ] = useState(1);
	const [ currentItem, setCurrentItem ] = useState(startingIndex || null);
	const [ isTransitioning, setIsTransitioning ] = useState(false);
	const numChildren = children.length;
	const hasMultipleChildren = numChildren > 1;

	const redraw = () => {
		const viewportWidth = Math.min(window.innerWidth, MAX_CONTAINER_SIZE);
		const intCartGap = parseInt(cardGap || 0, 10);
		const carouselContainer = containerRef.current;
		const cardWidth = carouselContainer?.firstChild?.getBoundingClientRect().width;
		const arrowMargin = arrowRef.current
			? parseInt(window.getComputedStyle(arrowRef.current).marginLeft, 10) + parseInt(window.getComputedStyle(arrowRef.current).marginRight, 10)
			: 0;
		const arrowWidth = arrowRef.current
			? arrowRef.current.getBoundingClientRect().width + arrowMargin
			: 0;
		const totalArrowWidth = arrowWidth * 2;
		const availableWidth = (viewportWidth + intCartGap) - totalArrowWidth;
		const newCardsToFit = Math.floor((availableWidth + intCartGap) / cardWidth);
		const newWidth = (cardWidth * newCardsToFit) - intCartGap;

		carouselContainer.style.width = viewportWidth > SIZING_CUTOFF ? `${newWidth}px` : "100%";

		setCardsToFit(newCardsToFit);
		forceUpdate(true);
	};

	const setupResizeListenerEffect = () => {
		if (!ignoreResize) {
			const handleResize = debounce(redraw, 200);
			redraw();
			window.addEventListener("resize", handleResize);

			return () => window.removeEventListener("resize", handleResize);
		}
	};

	const onCurrentItemChanged = () => {
		const newCurrentItem = currentItem === null ? 0 : currentItem;

		if (currentItem === null) {
			setCurrentItem(startingIndex || 0);
		} else if (isFinite(currentItem)) {
			handleNavigation(currentItem);
		}

		if (isFunction(onItemChange)) {
			onItemChange(newCurrentItem);
		}
	};

	const onIsTransitioningChanged = () => {
		if (!isTransitioning) {
			if (isFunction(onTransitionEnd)) {
				onTransitionEnd();
			}
		}
	};

	const handleNavigation = nextIndex => {
		let newIsTransitioning;
		const maxIndex = numChildren - cardsToFit;

		if (nextIndex < 0) {
			nextIndex = 0;
		} else if (numChildren > cardsToFit && nextIndex > maxIndex) {
			nextIndex = maxIndex;
		} else if (nextIndex >= numChildren) {
			nextIndex = maxIndex;
		} else if (nextIndex > maxIndex) {
			nextIndex = maxIndex;
			nextIndex = nextIndex < 0 ? 0 : nextIndex;
		}

		newIsTransitioning = nextIndex !== currentItem;
		setIsTransitioning(newIsTransitioning);
		setCurrentItem(nextIndex);

		if (newIsTransitioning) {
			if (isFunction(onItemChange)) {
				onItemChange(nextIndex);
			}

			if (isFunction(onTransitionStart)) {
				onTransitionStart();
			}
		}
	};

	const handlePageChange = (currentItem) => {
		setCurrentItem(currentItem);

		if (isFunction(onItemChange)) {
			onItemChange(currentItem);
		}
	};

	const handleSwitching = (newIndex, type) => {
		if (!isTransitioning) {
			setIsTransitioning(true);

			if (isFunction(onTransitionStart)) {
				onTransitionStart();
			}
		} else if (type === "end") {
			handleTransitionEnd();
		}
	};

	const handleTransitionEnd = () => {
		setIsTransitioning(false);
	};

	useEffect(setupResizeListenerEffect, []);
	useEffect(onCurrentItemChanged, [currentItem]);
	useEffect(onIsTransitioningChanged, [isTransitioning]);
	useEffect(() => {
		if (isFinite(changeToIndex) && changeToIndex !== currentItem) {
			setCurrentItem(changeToIndex);
		}
	}, [changeToIndex]);

	const [ dictionaryContent ] = useDictionaryContent();

	return (
		<div data-component="Carousel" className={`${styles.carousel} ${color in styles ? styles[color] : ""} ${fullBleed ? styles.fullBleed : ""} ${type ? styles[`carousel${type}`] : ""}`}>

			{hasMultipleChildren &&
				<div ref={arrowRef} className={`${styles.arrowWrapperLeft} carouselArrowWrapper carouselArrowWrapperLeft`}>
					<button
						className={`${styles.leftArrow} ${!circular && (!currentItem || currentItem <= 0) ? styles.hide : ""}`}
						onClick={() => setCurrentItem(currentItem === 0 ? numChildren - 1 : currentItem - PAGE_SIZE)}
						aria-label={dictionaryContent?.labelPrevious || "Previous"}
					>
						<Arrow/>
					</button>
				</div>
			}

			<div ref={containerRef} className={styles.carouselContainer}>
				{numChildren > 0 &&
				<div className={`${styles.swipeableContainer} ${type ? styles[`width${type}`] : ""}`}>
					<SwipeableViews
						disableLazyLoading
						resistance={hasMultipleChildren}
						ignoreNativeScroll
						index={currentItem}
						onChangeIndex={handlePageChange}
						animateTransitions
						enableMouseEvents
						containerStyle={{ height: "100%" }}
						style={{ height: "100%", overflowX: "visible" }}
						onTransitionEnd={handleTransitionEnd}
						onSwitching={handleSwitching}
					>
						{children}
					</SwipeableViews>
				</div>
				}
			</div>

			{hasMultipleChildren &&
			<div className={`${styles.arrowWrapperRight} carouselArrowWrapper carouselArrowWrapperRight`}>
				<button
					className={`${styles.rightArrow} ${!circular && (currentItem >= numChildren - cardsToFit) ? styles.hide : ""}`}
					onClick={() => setCurrentItem((currentItem + PAGE_SIZE) % numChildren)}
					aria-label={dictionaryContent?.labelNext || "Next"}
				>
					<Arrow />
				</button>
			</div>
			}
		</div>
	);
};

Carousel.propTypes = {
	children: PropTypes.node,
	onItemChange: PropTypes.func,
	onTransitionStart: PropTypes.func,
	onTransitionEnd: PropTypes.func,
	type: PropTypes.oneOf(["Full", "RelatedPosts", "Instagram", "StorytellingImages"]),
	color: PropTypes.oneOf(["light", "dark"]),
	cardGap: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
	ignoreResize: PropTypes.bool,
	fullBleed: PropTypes.bool,
	startingIndex: PropTypes.number
};

export default Carousel;