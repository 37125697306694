import React from "react";
import PropTypes from "prop-types";
import moment from "moment";
import { isNonEmptyString } from "helpers";
import { ArticleCard } from "schema/fragments/Article";
import { useDictionaryContent } from "hooks/shell";
import { documentToPlainTextString } from "@contentful/rich-text-plain-text-renderer";
import TextTruncate from "react-text-truncate";

import Arrow from "globals/images/arrow.svg";
import Container from "components/Container";
import ConnectedComponent from "components/ConnectedComponent";
import Cta from "components/Cta";
import BasePadding from "components/BasePadding";
import Eyebrow from "components/Eyebrow";
import Heading from "components/Heading";
import Image from "components/Image";
import LocaleLink from "components/LocaleLink";
import VerticalSpacing from "components/VerticalSpacing";
import Video from "components/Video";

import styles from "./styles.css";

const Card = props => {
	const {
		routeSlug,
		featuredImage,
		featuredVideo,
		publishedDate,
		synopsis,
		copy,
		title,
		category
	} = props;
	const [ content ] = useDictionaryContent();
	const subcategoryKey = `subcategory${category?.name}Topic`;
	const subcategoryLabel = props.content && subcategoryKey in props.content ? props.content[subcategoryKey] : "";
	const formattedPublishedDate = moment(publishedDate).format("ll");
	const hasVideo = isNonEmptyString(featuredVideo);

	return (
		<div data-component="ArticleFeedCard" className={styles.articleFeedCard}>
			<meta name="thumbnail" content="featuredImage?.url" />
			<Container>
				<VerticalSpacing>
					<BasePadding mobile={{ left: false, right: false }}>
						<div className={styles.flexContainer}>
							<div className={styles.image}>
								<LocaleLink to={`/${category?.routeSlug}/${routeSlug}`}>
									{hasVideo
										? <Video url={featuredVideo} />
										: <Image url={featuredImage?.url} altText={featuredImage?.title} aspectRatio="16x9" />
									}
								</LocaleLink>
							</div>
							<div className={styles.rightContent}>
								{subcategoryLabel && <div className={styles.eyebrow}>
									<Eyebrow>{subcategoryLabel}</Eyebrow>
								</div>}
								<div className={styles.content}>
									<div className={styles.heading}>
										<LocaleLink to={`/${category?.routeSlug}/${routeSlug}`}>
											<Heading type="h2">{title}</Heading>
										</LocaleLink>
									</div>
									{(synopsis || copy) && <p className={styles.synopsis}>
										{synopsis
											? synopsis
											: <TextTruncate
												line={4}
												truncateText="…"
												text={documentToPlainTextString(copy.json)}
											/>
										}
									</p>}
									<div className={styles.date}>{formattedPublishedDate}</div>
									<Cta type="Link">
										<LocaleLink to={`/${category?.routeSlug}/${routeSlug}`}>
											<Arrow />{hasVideo ? content?.labelWatchMore : content?.labelReadMore}
										</LocaleLink>
									</Cta>
									<hr className={styles.line} />
								</div>
							</div>
						</div>
					</BasePadding>
				</VerticalSpacing>
			</Container>
		</div>
	)
};

const ArticleFeedCard = props => {
	const {
		content,
		fetchQuery
	} = props;

	if (content) {
		// If we already have the content for this card (parent query took care of it).
		return (
			<Card {...props.content} />
		)
	} else if (fetchQuery) {
		// If we don't know the content of the card, but have an ID to query it.
		const success = props => {
			return <Card {...props.article} />
		};
		return <ConnectedComponent query={ArticleCard} variables={{ id: fetchQuery }} success={success} />
	} else {
		return <></>
	}
};

ArticleFeedCard.propTypes = {
	content: PropTypes.shape({
		routeSlug: PropTypes.string,
		copy: PropTypes.object,
		featuredImage: PropTypes.object,
		featuredVideo: PropTypes.string,
		publishedDate: PropTypes.string,
		synopsis: PropTypes.string,
		title: PropTypes.string,
		category: PropTypes.object
	})
};

export default ArticleFeedCard;