import gql from "graphql-tag";

export const GetSubscriptionModule = gql`
    query GetSubscriptionModule($locale: String!) {
        moduleSubscriptionCollection(locale: $locale, limit: 1) {
            items {
                confirmationMessage
                description
                labelCallToAction
                labelEmptyText
                labelGenericError
                labelInvalidEmail
                labelEmailInUse
                submitEndpoint
                title
            }
        }
    }
`;