import React from "react";
import PropTypes from "prop-types";
import { isFunction } from "helpers";
import Container from "components/Container";

import styles from "./styles.css";


const LineCounter = ({ paused, currentItem, totalItems, vColor, animationDuration, onClick }) => {
	const isActive = index => currentItem === index;

	const handleClick = (index) => {
		if (isFunction(onClick)) {
			onClick(index);
		}
	};

	return (
		<div data-component="LineCounter">
			<Container>
				<ul className={`${styles.list} ${vColor ? styles[`color${vColor}`] : ""}`}>
					{Array(totalItems).fill().map((_, index) => (
						<li key={index} className={isActive(index) ? styles.active : ""} >
							<button className={styles.segmentButton} onClick={() => handleClick(index)} />
							<div className={styles.line} style={{
								animationDuration: animationDuration,
								animationPlayState: paused ? "paused" : "running"
							}} />
						</li>
					))}
				</ul>
			</Container>
		</div>
	);
};

LineCounter.propTypes = {
	currentItem: PropTypes.number.isRequired,
	totalItems: PropTypes.number.isRequired,
	vColor: PropTypes.string,
	onClick: PropTypes.func,
	animationDuration: PropTypes.string,
};

LineCounter.defaultProps = {
	animationDuration: "3000ms"
};

export default LineCounter;