import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import Div100vh from "react-div-100vh"
import { isFunction, isNonEmptyArray, keyCodes, lockBackground } from "helpers";
import BasePadding from "components/BasePadding";
import Carousel from "components/Carousel";
import CloseButton from "components/CloseButton";
import DotCounter from "components/DotCounter";
import Image from "components/Image"
import NumericCounter from "components/NumericCounter";

import styles from "./styles.css";


const Lightbox = ({ images, isOpen, startingIndex, onClose }) => {
	const lightboxRef = useRef(null);
	const [ currentItem, setCurrentItem ] = useState(0);
	const [ newCarouselIndex, setNewCarouselIndex ] = useState(startingIndex || 0);
	const numImages = isNonEmptyArray(images) ? images.length : 0;

	const onItemChange = currentItem => {
		setCurrentItem(currentItem);
	};

	const handleClose = () => {
		if (isFunction(onClose)) {
			onClose();
		}
	};

	const handleDotClick = newIndex => {
		if (newIndex !== newCarouselIndex) {
			setNewCarouselIndex(newIndex)
		}
	}

	const onEscapePressed = (event) => {
		if (event.keyCode === keyCodes.esc) {
			handleClose();
		}
	};

	useEffect(() => {
		window.addEventListener("keyup", onEscapePressed);

		return () => window.removeEventListener("keyup", onEscapePressed);
	}, []);

	useEffect(() => {
		lockBackground(isOpen, lightboxRef.current);
	}, [isOpen, lightboxRef]);

	return (
		<div data-component="Lightbox" className={`${styles.lightbox} ${isOpen ? styles.isOpen : ""}`} ref={lightboxRef}>
			{isOpen &&
			<>
				<div className={styles.closeButtonContainer}>
					<CloseButton onClick={handleClose} isWhite />
				</div>
				<Div100vh className={styles.lightboxInnerContainer}>
					<div className={styles.carouselContainer}>
						<Carousel type="Full" color="dark" startingIndex={startingIndex} onItemChange={onItemChange} ignoreResize changeToIndex={newCarouselIndex}>
							{images.map((image, index) => {
								return (
									<div key={index} className={styles.imageWrapper}>
										<div className={styles.innerImageWrapper}>
											<Image url={image.url} altText={image.altText} fillContainer />
										</div>
									</div>
								);
							})}
						</Carousel>
					</div>

					<div className={`${styles.dotCounterWrapper} ${styles.mobile}`}>
						<DotCounter currentItem={currentItem} totalItems={numImages} onClick={handleDotClick} />
					</div>

					<div className={styles.footer}>
						<BasePadding desktop={{ disabled: true }}>
							<div className={styles.footerContent}>
								<div className={`${styles.numericCounterWrapper} ${styles.desktop}`}>
									<NumericCounter currentItem={currentItem} totalItems={numImages} />
								</div>
								<span>{images[currentItem]?.description}</span>
							</div>
						</BasePadding>
					</div>
				</Div100vh>
			</>
			}
		</div>
	);
};

Lightbox.propTypes = {
	images: PropTypes.arrayOf(PropTypes.shape({
		url: PropTypes.string,
		altText: PropTypes.string
	})),
	isOpen: PropTypes.bool,
	startingIndex: PropTypes.number,
	onClose: PropTypes.func
};

export default Lightbox;