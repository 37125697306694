import React from "react";
import { node, bool } from "prop-types";

import styles from "./styles.css";

const Container = ({ children, showOverflow }) => {
	return (

		<div data-component="Container" className={showOverflow ? styles.showOverflow : styles.container}>
			{children}
		</div>
	)
};

Container.propTypes = {
	children: node.isRequired,
	showOverflow: bool
};

export default Container;