import React from "react";
import PropTypes from "prop-types";

import styles from "./styles.css";

const Subheading = ({ children }) => {
	return (
		<strong data-component="Subheading" className={styles.subheading}>
			{children}
		</strong>
	);
};

Subheading.propTypes = {
	children: PropTypes.node
};

export default Subheading;