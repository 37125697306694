import gql from "graphql-tag";
import { AssetMinimalFieldsFragment } from "./asset";

export const LinkAllFieldsFragment = gql`
    fragment LinkAllFieldsFragment on Link {
        altTextIcon
        icon {
            ...AssetMinimalFieldsFragment
        }
        labelLink
        openInNewTabWindow
        targetRoute
        routeSlug
    },
    ${AssetMinimalFieldsFragment}
`;
