import React, { useState, useEffect } from "react";
import { useDictionaryContent } from "hooks/shell";
import { AlbumModuleQuery } from "schema/fragments/Poster";
import {
	useGlobalState,
	countSavedPosters,
	isNonEmptyArray,
	isPosterCollected,
	getLocaleId
} from "helpers";

import BasePadding from "components/BasePadding";
import ConnectedComponent from "components/ConnectedComponent"
import Container from "components/Container";
import Cta from "components/Cta";
import Image from "components/Image";
import Lightbox from "components/Lightbox";
import RichText from "components/RichText";
import ShareBar from "components/ShareBar";

import Lock from "./images/lock.svg"
import Swirls from "globals/images/graphic-two-circles.svg"
import CircleLines from "globals/images/graphic-circle-line.svg"

import styles from "./styles.css";


const PosterCollection = () => {

	const [, setIsGeneral ] = useGlobalState("isGeneral");
	useEffect(() => {
		setIsGeneral(true);
		return () => setIsGeneral(false);
	}, [setIsGeneral]);

	const [ dictionaryContent ] = useDictionaryContent();
	const [ isLightboxOpen, setIsLightboxOpen ] = useState(false);
	const [ isAssembledPosterLightboxOpen, setIsAssembledPosterLightboxOpen ] = useState(false);
	const [ lightboxIndex, setLightboxIndex ] = useState(0);
	const [ pageTitle , setPageTitle ] = useGlobalState("pageTitle");

	const onImageClick = index => {
		setLightboxIndex(index);
		setIsLightboxOpen(true);
	};

	const onAssembledPosterButtonClick = () => {
		setIsAssembledPosterLightboxOpen(true);
	};

	const onLightboxClose = () => {
		setIsLightboxOpen(false);
	};

	const onAssembledPosterLightboxClose = () => {
		setIsAssembledPosterLightboxOpen(false);
	};

	useEffect(() => {
		setPageTitle(dictionaryContent?.labelPosterCollectionPageTitle + " | Neymar Jr.")
	}, [dictionaryContent]);

	const success = ({ moduleAlbumCollection }) => {
		const posters = moduleAlbumCollection?.items[0]?.postersCollection?.items;
		const content = moduleAlbumCollection?.items[0];

		const collectedPosterImages = isNonEmptyArray(posters)
			? posters.filter(poster => isPosterCollected(poster.sys.id)).map(poster => poster.image)
			: [];

		const count = countSavedPosters();
		const areAllCollected = count >= 12;
		let collectedIndex = 0;

		return (
			<div data-component="Container\PosterCollection" className={styles.album}>
				<Container>
					<BasePadding>
						<div className={styles.textContainer}>
							<BasePadding mobile={{left: false, right: false}}>
								<div className={styles.heading}>{areAllCollected ? content.posterCollectionPageTitleComplete : content.posterCollectionPageTitle}</div>
								<p>{areAllCollected ? content.posterCollectionPageSubtitleComplete : content.posterCollectionPageSubtitle}</p>
								{areAllCollected &&
									<ShareBar />
								}
								<div className={styles.subheading}>{count}/12 {dictionaryContent?.labelCollected}</div>
							</BasePadding>
						</div>

						<div className={styles.posterContainer}>
							{posters.map((poster, index) => {
								const isCollected = isPosterCollected(poster.sys.id);
								let lightboxIndex;

								if (isCollected) {
									lightboxIndex = collectedIndex;
									collectedIndex = collectedIndex + 1;
								}

								return(
									<div className={(count > 0) ? styles.box : styles.emptyBox} key={index}>
										{isCollected ?
											<button className={styles.galleryButton} onClick={() => onImageClick(lightboxIndex)} key={`cell-${index}`}>
												<Image url={poster.image.url} />
											</button>
											:
											<Lock />
										}
									</div>
								)
							})
							}
						</div>
						<div className={styles.textContainer}>
							<BasePadding mobile={{ left: false, right: false }}>
								{areAllCollected && <Cta><button onClick={() => onAssembledPosterButtonClick()} className={styles.fullPosterButton}>{content.labelViewFullPoster}</button></Cta>}
								<div className={styles.subheading}>{content.posterCollectionPageHowItWorks}</div>
								<RichText data={content.posterCollectionPageHowItWorksDescription} />
								<hr />
								<div className={styles.finePrint}>
									<div className={styles.subheading}>{content.posterCollectionPageThingsToNote}</div>
									<RichText data={content.posterCollectionPageThingsToNoteDescription} />
								</div>
							</BasePadding>
						</div>
					</BasePadding>
				</Container>
				<div className={styles.swirls}><Swirls /></div>
				<div className={styles.circleLines}><CircleLines /></div>
				<Lightbox isOpen={isLightboxOpen} images={collectedPosterImages} onClose={onLightboxClose} startingIndex={lightboxIndex} />
				<Lightbox isOpen={isAssembledPosterLightboxOpen} images={[content?.assembledPoster]} onClose={onAssembledPosterLightboxClose} startingIndex={0} />
			</div>
			);
		};

	return <ConnectedComponent query={AlbumModuleQuery} success={success} />
};

export default PosterCollection;
