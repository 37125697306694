import { useState, useEffect } from "react";

export const useFetch = url => {
	const [data, setData] = useState([]);
	const [loading, setLoading] = useState(true);

	const fetchUrl = async () => {
		const response = await fetch(url);
		const json = await response.json();
		setData(json.data);
		setLoading(false);
	};

	useEffect(() => {
		fetchUrl();
	}, []);
	
	return [data, loading];
};