import React, { useState } from "react";
import { func } from "prop-types";
import { useCookieContent } from "hooks/shell";
import { getAcceptedCookie, storeAcceptedCookie } from "helpers";
import RichText from "components/RichText";
import Cta from "components/Cta";
import LoadingIndicator from "components/LoadingIndicator"

import styles from "./styles.css";

const Cookie = () => {
	const [isCookieOpen, setIsCookieOpen] = useState("isCookieOpen", getAcceptedCookie());

	const [ content ] = useCookieContent();

	if (!content) {
		return "";
	}

	const acceptCookie = () => {
		storeAcceptedCookie(true);
		setIsCookieOpen(false);
	};

	return getAcceptedCookie() ? "" : (
		<div data-component="Cookie" className={isCookieOpen ? styles.cookie : styles.cookieClosed}>
			<div className={styles.contentContainer}>
				<RichText className={styles.text} data={content.message} /> 
			</div>
			<Cta><button onClick={acceptCookie}>{content.labelCallToAction}</button></Cta>
		</div>
	)
};

Cookie.propTypes = {
	onClick: func,
};

export default Cookie;