import React from "react";
import { func } from "prop-types";
import { useDictionaryContent } from "hooks/shell";
import { isFunction } from "helpers";

import styles from "./styles.css";



const CloseButton = ({ onClick, isWhite }) => {
	const [ dictionaryContent ] = useDictionaryContent();

	return (
		<div data-component="CloseButton" className={styles.closeButton} onClick={isFunction(onClick) ? onClick : ""} aria-label={dictionaryContent.labelClose}>
			<div className={isWhite ? styles.white : ""}>
				<span></span>
				<span></span>
			</div>
		</div>
	)
};

CloseButton.propTypes = {
	onClick: func,
};

export default CloseButton;