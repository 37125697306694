import React, { useState, useEffect } from "react";
import { useApolloClient, useQuery } from "react-apollo-hooks";
import { FilteredPostsQuery, GetPostSlugsFromIds } from "schema/pageQueries";
import { AlbumModuleQuery } from "schema/fragments/Poster";
import { useDictionaryContent } from "hooks/shell";
import {
	isNonEmptyArray,
	assembleFilterVariables,
	getResultPage,
	shuffleArray,
	isFunction,
	useGlobalState,
	removeFalsyValues,
	isNonEmptyString,
	resetQueryString,
	queryStringifyFilters,
	isObject,
	parseQueryString,
	validatePostSlugs,
	communityRouteSlugs,
	cultureRouteSlugs,
	footballRouteSlugs,
} from "helpers";
import ComponentComposer from "components/ComponentComposer";
import Cta from "components/Cta";
import Filter from "components/Filter";
import Subscription from "components/Subscription";
import LoadingIndicator from "components/LoadingIndicator/LoadingIndicator";

import styles from "./styles.css";

const Posts = ({ slug, category, setHeaderTitle }) => {
	const apollo = useApolloClient();
	const [ isInitialized, setIsInitialized ] = useState(false);
	const [ headerTitle , globalSetHeaderTitle ] = useGlobalState("headerTitle");
	const [ pageTitle , setPageTitle ] = useGlobalState("pageTitle");
	const [hasLoaded, setHasLoaded] = useState(false);
	const [initialFilters] = useState(parseQueryString() || {});
	const [filterVars, setFilterVars] = useState(initialFilters);
	const [posts, setPosts] = useState([]);
	const [pinnedItems, setPinnedItems] = useState([]);
	const [posters, setPosters] = useState([]);
	const [ currentPage, setCurrentPage ] = useState(0);
	const [ postOffsets, setPostOffsets ] = useState({ article: 0, gallery: 0, video: 0 });
	const [ dictionary ] = useDictionaryContent();
	const hasFilters = isNonEmptyArray(Object.keys(filterVars));
	const isFirstResultsPage = currentPage === 1;

	const { data: posterContent } = useQuery(AlbumModuleQuery);

	const queryVariables = {
		category: category,
		categoryRouteSlug: slug,
		...filterVars
	};

	const getPosterSpacing = (min = 0) => Math.floor(Math.random() * Math.floor(3)) + min;

	const getTheme = () => {
		if (communityRouteSlugs.includes(slug)) {
			return "Community"
		}
		if (cultureRouteSlugs.includes(slug)) {
			return "Culture"
		}
		if (footballRouteSlugs.includes(slug)) {
			return "Football"
		}
	}

	const mixPostsAndPosters = (posts, posters) => {
		const mixedPostAndPosters = [];
		const theme = getTheme();
		const mutablePosters = [...posters].filter(poster => {
			return poster.theme === theme
		});
		let posterSpacing = getPosterSpacing(0);

		posts.forEach((post, index) => {
			mixedPostAndPosters.push(post);

			if (index === posterSpacing) {
				const posterToInsert = mutablePosters.shift();

				if (posterToInsert) {
					mixedPostAndPosters.push(posterToInsert);
				}

				posterSpacing += getPosterSpacing(4);
			}
		});

		return mixedPostAndPosters;
	};

	const lookupSlugsByIds = ids => {
		return apollo.query({
			query: GetPostSlugsFromIds,
			variables: { postIds: removeFalsyValues(ids) }
		})
			.then(({ data }) => {

				return [
					...data.articleCollection?.items || [],
					...data.galleryCollection?.items || [],
					...data.videoCollection?.items || []
				].map(post => post.routeSlug );
			})
			.catch((x) => {
				console.error(x);
				return [];
			})
	};

	const removeInvalidPinnedItems = async items => {
		const postIds = [];
		const keepers = [];
		const orderHash = {};
		const isPost = item => ["Article", "Gallery", "Video"].includes(item.__typename);

		items.forEach((item, index) => {
			const itemId = item.sys.id;
			orderHash[itemId] = index;
			isPost(item) ? postIds.push(itemId) : keepers.push(item);
		});

		const slugs = await lookupSlugsByIds(postIds);
		const validPostIds = await validatePostSlugs(apollo, slugs);
		const filteredItems = items.filter(item => !!validPostIds[item.sys.id]);

		return [...filteredItems, ...keepers]
			.sort((a, b) => orderHash[a.sys.id] < orderHash[b.sys.id] ? -1 : 1);
	};

	const processUnfilteredList = () => {
		const vars = {
			...queryVariables,
			includeLanding: !isNonEmptyArray(pinnedItems),
			includePosters: !isNonEmptyArray(posters),
			articleOffset: postOffsets?.article || 0,
			galleryOffset: postOffsets?.gallery || 0,
			videoOffset: postOffsets?.video || 0
		};

		return getResultPage({
			query: FilteredPostsQuery,
			variables: vars,
			pageSize: currentPage === 1 ? 12 : null,
			filterResults: (post, { pinnedItems: incomingPinnedItems }) => {
				const categoryMatches = post.category?.routeSlug === slug;

				const notInPinnedItems = () => {
					const allPinned = [ ...pinnedItems, ...incomingPinnedItems ];

					return !allPinned.find(pinned => pinned.sys.id === post.sys.id);
				};

				return categoryMatches && notInPinnedItems();
			},
			apolloClient: apollo,
			offsets: postOffsets,
			errorPolicy: "ignore"
		})
			.then((data) => {
				const { posts: incomingPosts, pinnedItems, posters: incomingPosters, offsets } = data;
				let entries = posts;

				if (isFirstResultsPage) {
					if (isNonEmptyArray(incomingPosters)) {
						shuffleArray(incomingPosters);
						setPosters(incomingPosters);
						entries = mixPostsAndPosters(incomingPosts, incomingPosters);
					} else if (isNonEmptyArray(posters)) {
						entries = mixPostsAndPosters(incomingPosts, posters);
					} else {
						entries = entries.concat(incomingPosts);
					}

					removeInvalidPinnedItems(pinnedItems)
						.then(pinnedItems => {
							if (isNonEmptyArray(pinnedItems)) {
								setPinnedItems(pinnedItems);
							}
						})
						.catch(x => console.error(x));
				} else if (isNonEmptyArray(incomingPosts)) {
					entries = entries.concat(incomingPosts);
				}

				setPosts(entries);

				if (offsets) {
					setPostOffsets(offsets);
				}
			});
	};

	const processFilteredList = () => {
		const vars = {
			...queryVariables,
			articleOffset: postOffsets?.article || 0,
			galleryOffset: postOffsets?.gallery || 0,
			videoOffset: postOffsets?.video || 0
		};

		return getResultPage({
			query: FilteredPostsQuery,
			variables: vars,
			filterResults: post => post.category?.routeSlug === slug,
			apolloClient: apollo,
			offsets: postOffsets
		})
			.then(({ posts: incomingPosts, offsets }) => {
				let entries = posts;

				if (isNonEmptyArray(incomingPosts)) {
					entries = entries.concat(incomingPosts);
				}

				setPosts(entries);
				setPinnedItems([]);

				if (offsets) {
					setPostOffsets(offsets);
				}
			});
	};

	const onApplyFilters = ({ filters, filterGroups, localeFilters }) => {
		const assembledFilterVars = assembleFilterVariables({ filters, filterGroups });

		setPosts([]);
		setPostOffsets({ article: 0, gallery: 0, video: 0 });
		setFilterVars(assembledFilterVars);

		if (isObject(localeFilters)) {
			queryStringifyFilters(localeFilters);
		}
	};

	const onResetFilters = () => {
		setPosts([]);
		setPostOffsets({ article: 0, gallery: 0, video: 0 });
		setFilterVars({});
		resetQueryString();
	};

	const onShowMore = () => {
		setCurrentPage(currentPage + 1);
	};

	const onLoaded = () => {
		setHasLoaded(true);
	};

	const callSetHeaderTitle = () => {
		if (isFunction(setHeaderTitle)) {
			setHeaderTitle();
		} else {
			globalSetHeaderTitle("");
		}
	};

	useEffect(() => {
		if (currentPage === 1) {
			setHasLoaded(false);
			(hasFilters ? processFilteredList() : processUnfilteredList())
				.finally(onLoaded)
		} else {
			setCurrentPage(1);
		}
	}, [filterVars]);

	useEffect(() => {
		if (currentPage > 0) {
			if (currentPage === 1) {
				setHasLoaded(false);
			}

			(hasFilters ? processFilteredList() : processUnfilteredList())
				.finally(onLoaded)
		}
	}, [currentPage]);

	useEffect(() => {
		if (isInitialized) {
			setPosters([]);
			setPinnedItems([]);
			onResetFilters();
			callSetHeaderTitle();
		}
	}, [slug]);

	useEffect(callSetHeaderTitle, [setHeaderTitle]);

	useEffect(() => {
		if (headerTitle !== pageTitle) {
			const updatedPageTitle = isNonEmptyString(headerTitle)
			? `${headerTitle.charAt(0).toUpperCase()}${headerTitle.slice(1)} | Neymar Jr.`
			: "Neymar Jr.";

			setPageTitle(updatedPageTitle)
		}
	}, [headerTitle, pageTitle]);

	useEffect(() => setIsInitialized(true), []);

	return (
		<>
			<Filter categorySlug={slug} onApply={onApplyFilters} onReset={onResetFilters} initialFilters={initialFilters}/>

			<div className={styles.pageContainer}>
				{hasLoaded ? <>
					{isNonEmptyArray(pinnedItems) && pinnedItems.map((item, index) => (
						<ComponentComposer isFirst={index === 0} componentName={item.__typename} id={item.sys.id} key={index} />
					))}

					{posts.map((item, index) => {
						const additionalProps = item.__typename === "ModuleAlbumPoster" ? { module: posterContent?.moduleAlbumCollection?.items[0] || {} } : {};

						return <ComponentComposer componentName={item.__typename} content={item} key={index} {...additionalProps} />
					})}

					<div className={styles.loadMoreWrapper}>
						<Cta color="Black">
							<button onClick={onShowMore}>{dictionary?.labelLoadMore}</button>
						</Cta>
					</div>

					<Subscription/>
				</>
				: <LoadingIndicator/>
				}
			</div>
		</>
	);
};

export default Posts;