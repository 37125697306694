import gql from "graphql-tag";

const {
	REACT_APP_PREVIEW_API: PREVIEW_API
} = process.env;

export const ProductShowcaseQuery = gql`
	query ModuleProductShowcaseQuery($locale: String!, $id: String!) {
		moduleProductShowcase(preview: ${PREVIEW_API === "true"}, locale: $locale, id: $id) {
			product {
				description
				imagesCollection(limit: 20) {
					items {
						url
						title
					}
				}
				labelCallToAction
				quote
				targetRoute
				title
			}
		}
	}
`;