import React, { useState } from "react";
import { PosterQuery } from "schema/fragments/Poster";
import { storeSavedPoster, isPosterCollected, countSavedPosters } from "helpers";

import BasePadding from "components/BasePadding";
import CloseButton from "components/CloseButton";
import ConnectedComponent from "components/ConnectedComponent";
import Container from "components/Container";
import Cta from "components/Cta";
import Heading from "components/Heading";
import Image from "components/Image";
import ShareBar from "components/ShareBar";
import VerticalSpacing from "components/VerticalSpacing";

import BottomRip from "./images/bottom-rip.svg";
import TopRip from "./images/top-rip.svg";

import styles from "./styles.css";

const Card = props => {
	const {
		image: {
			url,
			title
		},
		quote,
		icon,
		sys,
		module: {
			collectionSuccessTitle,
			collectionsSuccessSubtitle,
			collectionDuplicateTitle,
			collectionDuplicateSubtitle,
			collectionCompleteTitle,
            collectionCompleteSubtitle,
			collectedIconDark,
			notCollectedIconDark,
			labelCallToAction,
			targetRoute,
			footnote
		}
	} = props;

	const [isModalOpen, setIsModalOpen] = useState(false);
	const [isPosterJustSaved, setPosterJustSaved] = useState(false);

	const openModal = () => {
		setIsModalOpen(true);
	};

	const closeModal = () => {
		setIsModalOpen(false);
	};

	const savePoster = () => {
		storeSavedPoster(sys.id);
		setPosterJustSaved(true);
	};

	const count = countSavedPosters();

	return (
		<div data-component="Poster">
			<Container>
				<VerticalSpacing>
					<BasePadding mobile={{ disabled: true }}>
						<div className={styles.container}>
							<div className={styles.topRip}><TopRip /></div>
							<div className={styles.posterContainer}>
								<div className={`${styles.imageWrapper} ${styles.imageWrapperCollected}`}>
									{!isPosterJustSaved && <Image url={url} altText={title} />}
									{!isPosterJustSaved && <Image url={url} altText={title} useBackgroundImage />}
									{isModalOpen && <div className={styles.modal}>
										<button className={styles.closeButtonContainer} onClick={closeModal}><CloseButton isWhite /></button>
										<Heading type="h2" color="White">{collectionDuplicateTitle}</Heading>
										<p>{collectionDuplicateSubtitle}</p>
										<Cta><a href={targetRoute}>{labelCallToAction}</a></Cta>
									</div>}

									{(isPosterJustSaved && count < 12) &&
									<div className={styles.collected}>
										{collectedIconDark && <img src={collectedIconDark.url} alt={collectedIconDark.title} className={styles.iconLight} />}
										<Heading type="h2" color="White">{collectionSuccessTitle}</Heading>
										<p>{collectionsSuccessSubtitle}</p>
										<Cta><a href={targetRoute}>{labelCallToAction}</a></Cta>
										<p>{footnote}</p>
									</div>
									}
									{(isPosterJustSaved && count >= 12) &&
									<div className={styles.collected}>
										{collectedIconDark && <img src={collectedIconDark.url} alt={collectedIconDark.title} className={styles.iconLight} />}
										<Heading type="h2" color="White">{collectionCompleteTitle}</Heading>
										<p>{collectionCompleteSubtitle}</p>
										<ShareBar />
										<a href={targetRoute} className={styles.link}>{labelCallToAction}</a>
									</div>
									}
								</div>

								{!isPosterJustSaved &&
								<>
									{icon ?
									<div className={styles.iconContainer}>
										<img src={icon.url} alt={icon.title} />
									</div> :
									<blockquote>{quote}</blockquote>
									}
								</>
								}
								<div className={styles.icon}>
									{isPosterCollected(sys.id) ?
									<>
										{!isPosterJustSaved &&
										<button className={styles.button} onClick={openModal}>
											{collectedIconDark && <img src={collectedIconDark.url} alt={collectedIconDark.title}/>}
										</button>
										}
									</>
									:
									<button className={styles.button} onClick={savePoster}>
										{notCollectedIconDark && <img src={notCollectedIconDark.url} alt={notCollectedIconDark.title}/>}
									</button>
									}
								</div>
							</div>
							<div className={styles.bottomRip}><BottomRip /></div>
						</div>
					</BasePadding>
				</VerticalSpacing>
			</Container>
		</div>
	)
};

const Poster = props => {
	const {
		content,
		module,
		fetchQuery
	} = props;

	if (content) {
		// If we already have the content for this card (parent query took care of it).
		return (
			<Card {...props.content} module={module} />
		)
	} else if (fetchQuery) {
		// If we don't know the content of the card, but have an ID to query it.
		const success = props => {
			return <Card {...props.moduleAlbumCollection} />
		};
		return <ConnectedComponent query={PosterQuery} variables={{ id: fetchQuery }} success={success} />
	} else {
		return <></>
	}
};

export default Poster;