import React, { useEffect } from "react";
import { useQuery } from "react-apollo-hooks";
import { removeFalsyValues } from "helpers";
import { useShellContent } from "hooks/shell";
import { GetShell } from "schema/shell";

const Shell = ({ children }) => {
	const [, setShell] = useShellContent();
	const { data } = useQuery(GetShell);
	const isShellCollectionReady = !!data?.shellCollection?.items[0];
	
	useEffect(() => {
		if (isShellCollectionReady) {
			const shell = data.shellCollection.items[0];
			const dictionary = data.dictionaryCollection.items[0];

			const updatedShell = {
				...shell,
				dictionary: {
					...shell.dictionary,
					...dictionary
				},
				header: {
					...shell.header,
					categoriesCollection: {
						...shell.header.categoriesCollection,
						items: removeFalsyValues(shell.header.categoriesCollection.items)
					},
					linksCollection: {
						...shell.header.linksCollection,
						items: removeFalsyValues(shell.header.linksCollection.items)
					},
				},
				footer: {
					...shell.footer,
					followLinksCollection: {
						...shell.footer.followLinksCollection,
						items: removeFalsyValues(shell.footer.followLinksCollection.items)
					},
					group1LinksCollection: {
						...shell.footer.group1LinksCollection,
						items: removeFalsyValues(shell.footer.group1LinksCollection.items)
					},
					group2LinksCollection: {
						...shell.footer.group2LinksCollection,
						items: removeFalsyValues(shell.footer.group2LinksCollection.items)
					},
					partnerLinksCollection: {
						...shell.footer.partnerLinksCollection,
						items: removeFalsyValues(shell.footer.partnerLinksCollection.items)
					}
				}
			};

			setShell(updatedShell);
		}
	}, [isShellCollectionReady]);

	return <div data-component="Shell">{children}</div>;
};

export default Shell;