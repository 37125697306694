import React, { useState, useEffect } from "react";
import Div100VH from "react-div-100vh";
import { useApolloClient, useQuery } from "react-apollo-hooks";
import {
	isNonEmptyArray,
	isNonEmptyString,
	isFunction,
	getEnglishCategorySlug,
	useGlobalState,
} from "helpers";
import { LandingPageQuery, LandingPostsQuery } from "schema/pageQueries";
import BasePadding from "components/BasePadding";
import Carousel from "components/Carousel";
import Cover from "components/Cover";
import LineCounter from "components/LineCounter";
import LoadingIndicator from "components/LoadingIndicator";

import Swirls from "globals/images/graphic-circles.svg"
import styles from "./styles.css";

const TRANSITION_TIME_MS = 6000;
let timeout;

const Home = ({ onChange }) => {
	const apolloClient = useApolloClient();
	const [ isReady, setIsReady ] = useState(false);
	const [ currentItem, setCurrentItem ] = useState(0);
	const [ landingItems, setLandingItems ] = useState(null);
	const [ nikeCoverData, setNikeCoverData ] = useState(null);
	const [ pageTitle , setPageTitle ] = useGlobalState("pageTitle");

	const { data: coverData, loading: isCoverLoading, error: isCoverError } = useQuery(LandingPageQuery, { errorPolicy: "ignore" });

	const queryArticles = () => {
		if (!isCoverLoading && !isCoverError && isNonEmptyArray(coverData.landingCollection.items)) {
			const shellCoverData = coverData?.shellCoverCollection?.items[0] || {};
			const categoryOrder = shellCoverData?.categoriesCollection?.items || [];

			setNikeCoverData({
				isShop: true,
				targetUrl: shellCoverData.targetRoute,
				linkLabel: shellCoverData.labelNikeShop,
				pageTitle: <img className={styles.pageTitleIcon} src={shellCoverData.nikeSwooshLogo.url} alt={shellCoverData.nikeSwooshLogo?.title}/>,
				coverStory: {
					title: shellCoverData.nikeHeadline,
					coverImages: {
						desktop: shellCoverData.nikeCoverImage,
						mobile: shellCoverData.nikeCoverImageMobile,
					}
				},
			});

			const landingItems = [ ...(coverData?.landingCollection?.items || []) ];
			let sortedLandingItems = [];

			categoryOrder.forEach(({ routeSlug, name }) => {
				const index = landingItems.findIndex(landingItem => {
					const englishSlug = getEnglishCategorySlug(landingItem.routeSlug);
					return !![englishSlug, landingItem.routeSlug].includes(routeSlug);
				});

				if (index > -1) {
					const splicedItem = landingItems.splice(index, 1)[0];
					sortedLandingItems.push({ ...splicedItem, pageTitle: name });
				}
			});

			setLandingItems(sortedLandingItems);
		}
	};

	const extractImageDataFromPost = data => {
		const output = { desktop: null, mobile: null, featured: null };

		if (isNonEmptyString(data?.coverImageDesktop?.url)) {
			output.desktop = { ...data.coverImageDesktop };
		}

		if (isNonEmptyString(data?.coverImageMobile?.url)) {
			output.mobile = { ...data.coverImageMobile };
		}

		switch (data.__typename) {
			case "Article":
				if (isNonEmptyString(data?.featuredImage?.url)) {
					output.featured = { ...data.featuredImage };
				}
				break;
			case "Gallery":
				if (isNonEmptyString(data?.featuredImage?.url)) {
					output.featured = { ...data.featuredImage };
				} else if (isNonEmptyString(data?.galleryCollection?.items[0]?.url)) {
					output.featured = { ...data?.galleryCollection?.items[0] };
				}
				break;
		}

		return output;
	};

	const fillPosts = ({ articleCollection, galleryCollection, videoCollection }) => {
		const postHash = {};
		const updatedLandingItems = [];
		const postCollection = [
			...articleCollection.items,
			...galleryCollection.items,
			...videoCollection.items
		];

		postCollection.forEach(post => {
			const sysId = post?.sys?.id;
			postHash[sysId] = {
				title: post?.title,
				coverImages: extractImageDataFromPost(post)
			};
		});

		landingItems.forEach(landingItem => {
			const sysId = landingItem.coverStory?.sys?.id;

			if (!landingItem.isShop) {
				updatedLandingItems.push({
					...landingItem,
					coverStory: {
						...landingItem.coverStory,
						...(sysId in postHash ? postHash[sysId] : {})
					}
				});
			}
		});

		return updatedLandingItems;
	};

	const fetchPostData = () => {
		if (!isReady && isNonEmptyArray(landingItems)) {
			const postIds = [];
			landingItems.forEach(({ coverStory }) => {
				if (coverStory?.sys?.id) {
					postIds.push(coverStory?.sys.id);
				}
			});

			apolloClient.query({
				query: LandingPostsQuery,
				variables: { ids: postIds }
			}).then(response => {
				setLandingItems([
					...fillPosts(response.data),
					...([nikeCoverData] || [])
				]);
			}).catch((ex) => {
				// todo: set error and notify user somehow
				console.error("Something broke: %o", ex)
			}).finally(() => {
				setIsReady(true);
			});
		}
	};

	const startTimer = () => {
		if (isReady && isFinite(currentItem)) {
			if (timeout) {
				clearTimeout(timeout);
			}

			timeout = setTimeout(() => {
				setCurrentItem(
					numCovers > 1
						? (currentItem + 1) % numCovers
						: 0
				);
			}, TRANSITION_TIME_MS);
		}
	};

	const numCovers = isNonEmptyArray(landingItems) ? landingItems.length : 0;

	const onItemChange = incomingItemIndex => {
		if (isFinite(incomingItemIndex) && incomingItemIndex !== currentItem) {
			setCurrentItem(incomingItemIndex);

			if (isFunction(onChange)) {
				onChange({ cover: landingItems[incomingItemIndex] });
			}
		}
	};

	const notifyParentItemChanged = () => {
		if (isNonEmptyArray(landingItems) && isFunction(onChange)) {
			onChange({ cover: landingItems[currentItem] });
		}
	};

	useEffect(queryArticles, [coverData, isCoverLoading, isCoverError]);
	useEffect(fetchPostData, [landingItems, isReady]);
	useEffect(startTimer, [currentItem, isReady]);
	useEffect(notifyParentItemChanged, [isReady, currentItem]);

	useEffect(() => {
		setPageTitle("Neymar Jr.")
	}, []);

	return isCoverLoading
		? <LoadingIndicator />
		: (
		<div data-component="Containers\Home" className={styles.home}>
			<Div100VH className={styles.body}>
				<div className={styles.carouselContainer}>
					<Carousel type="Full" color="dark" changeToIndex={currentItem} onItemChange={onItemChange} ignoreResize fullBleed circular>
						{numCovers && landingItems.map((cover, index) => (
							<div className={styles.coverWrapper} key={index}>
								<Cover
									title={cover.coverStory?.title}
									route={cover.routeSlug}
									targetUrl={cover.targetUrl}
									coverImages={cover.coverStory?.coverImages}
									isShop={cover.isShop}
									linkLabel={cover.linkLabel}
								/>
							</div>
						))}
					</Carousel>
				</div>

				<div className={styles.lineCounterWrapper}>
					<BasePadding>
						<LineCounter
							totalItems={numCovers}
							currentItem={currentItem || 0}
							animationDuration={`${TRANSITION_TIME_MS}ms`}
							onClick={index => setCurrentItem(index)}
						/>
					</BasePadding>
				</div>

				<div className={styles.swirlWrapper}>
					<Swirls />
				</div>
			</Div100VH>
		</div>
	);
};

export default Home;