import React from "react";
import PropTypes from "prop-types";

import styles from "./styles.css";

const VerticalSpacing = ({ removeMargin, size, children }) => {
  const crunchClasses = () => {
    const classes = [removeMargin ? styles.removeMargin : styles.baseMargin];

    if (!removeMargin && size in styles) {
      classes.push(styles[size]);
    }

    return classes.join(" ");
  };

  return (
    <div data-component="VerticalSpacing" className={crunchClasses()}>
      {children}
    </div>
  );
};

VerticalSpacing.propTypes = {
  children: PropTypes.node,
  removeMargin: PropTypes.bool,
  size: PropTypes.oneOf(["normal", "half"])
};

export default VerticalSpacing;