import gql from "graphql-tag";
import { LinkAllFieldsFragment } from "schema/link";
import { AssetMinimalFieldsFragment } from "schema/asset";

const HeaderAllFieldsFragment = gql`
    fragment HeaderAllFieldsFragment on Header {
        categoriesCollection(limit: 20) {
            items {
                name
                theme
                routeSlug
            }
        }
        linksCollection(limit: 20) {
            items {
                ...LinkAllFieldsFragment
            }
        }
        logo {
            ...AssetMinimalFieldsFragment
        }
        altTextLogo
        labelLanguage
    },
    ${AssetMinimalFieldsFragment}
`;

export const FooterLabelsFragment = gql`
    fragment FooterLabelsFragment on Footer {
        copyrightLine1
        copyrightLine2
        labelContactUs
        labelFollowLinks
        labelGroup1Links
        labelGroup2Links
        labelPartnerLinks,
        nickname
    }
`;

export const FooterAllFieldsFragment = gql`
    fragment FooterAllFieldsFragment on Footer {
        ...FooterLabelsFragment,
        followLinksCollection {
            items {
                ...LinkAllFieldsFragment
            }
        }
        group1LinksCollection {
            items {
                ...LinkAllFieldsFragment
            }
        }
        group2LinksCollection {
            items {
                ...LinkAllFieldsFragment
            }
        }
        partnerLinksCollection {
            items {
                ...LinkAllFieldsFragment
            }
        }
    },
    ${FooterLabelsFragment},
    ${LinkAllFieldsFragment}
`;

export const GetShell = gql`
    query GetShell($locale: String!) {
        dictionaryCollection(locale: $locale, limit: 1) {
            items {
                labelFilters
                labelViewMore
                labelLoadMore
                labelWatchMore
                labelReadMore
                labelEmailRequired
                labelExplore
                labelApply
                labelReset
                labelNext
                labelPrevious
                labelWrittenBy
                labelClose
                labelOpen
                labelCollected
                labelRelatedPosts
                labelShare
                labelPosterCollectionPageTitle
                defaultCpCode
            }
        }
        shellCollection(locale: $locale, limit: 1) {
            items {
                sys {
                    id
                }
                header {
                    ...HeaderAllFieldsFragment
                }
                footer {
                    ...FooterAllFieldsFragment
                }
                cookiePolicy {
                    message {
                        json
                        links {
                            entries {
                                hyperlink {
                                    ...on General {
                                        routeSlug
                                    }
                                    sys {
                                        id
                                    }
                                }
                            }
                            assets {
                                hyperlink {
                                    url,
                                    title,
                                    description,
                                    sys {
                                        id
                                    }
                                }
                                block {
                                    contentType
                                    fileName
                                    title
                                    description
                                    url
                                    height
                                    width
                                    sys {
                                        id
                                    }
                                }
                            }
                        }
                    }
                    labelCallToAction
                }
                errorMessage {
                    backgroundImage {
                        title
                        url
                        description
                    }
                    errorMessageLine1
                    errorMessageLine2
                    labelLink
                }
            }
        }
    },
    ${HeaderAllFieldsFragment},
    ${FooterAllFieldsFragment},
`;

