import React from "react";
import { isNonEmptyString } from "helpers";

import { useGlobalState } from "helpers";
import { ProductShowcaseQuery } from "schema/fragments/ProductShowcase";
import { useDictionaryContent } from "hooks/shell";

import Cta from "components/Cta";
import BasePadding from "components/BasePadding";
import ConnectedComponent from "components/ConnectedComponent";
import Heading from "components/Heading";
import Image from "components/Image";
import VerticalSpacing from "components/VerticalSpacing";
import ThrowableArea from "components/ThrowableArea";

import CircleLines from "globals/images/graphic-circle-lines.svg"
import Circle from "globals/images/graphic-circle.svg"
import ExternalIcon from "globals/images/external-link-icon.svg"

import styles from "./styles.css";

const Card = props => {
	const {
		product: {
			description,
			imagesCollection: {
				items: originalItems
			},
			labelCallToAction,
			quote,
			targetRoute,
			title
		}
	} = props;

	const [ content ] = useDictionaryContent();
	const [ selectedTheme ] = useGlobalState("selectedTheme");

	const items = [...originalItems];
	items.splice(3, 0, { text: quote } );

	const route = targetRoute.includes("cp=") ? targetRoute : `${targetRoute}?cp=${content?.defaultCpCode}`;

	const renderHeading = () => {
		return (
			<div className={styles.copyContainer}>
				<Heading type="h2">{title}</Heading>
				<p className={styles.description}>{description}</p>
			</div>
		);
	};

	const renderGallery = () => {
		let patternCount = 0;

		return (
			<div className={styles.gallery} >
				{items.map((item, index) => {
					patternCount = patternCount >= 5 ? 1 : patternCount + 1;
					return (
					<div key={index} className={`${(item.text && isNonEmptyString(item.text)) ? "" : styles.hideQuotePosition} ${styles.item} ${styles[`position${patternCount}`]}`}>
						{item.url ?
							<Image url={item.url} alt={item.title}/>
						:
							<div className={isNonEmptyString(item.text) ? styles.quote : styles.hideQuote}>{item.text}</div>
						}
					</div>
					)
				})}
			</div>
		);
	};

	return (
		<VerticalSpacing>
			<div data-component="ProductShowcase" className={`${styles.productShowcase} ${selectedTheme in styles ? styles[selectedTheme] : ""}`}>
				<ThrowableArea className={styles.desktopThrowableArea}>
					<BasePadding desktop={{ left: true, right: false }}>
						<div className={styles.topContent}>
							{renderHeading()}
							{renderGallery()}
						</div>
					</BasePadding>
				</ThrowableArea>
				<div className={styles.mobileContent}>
					<BasePadding mobile={{ left: true, right: false }}>
						<div className={styles.topContent}>
							{renderHeading()}
							<ThrowableArea className={styles.mobileThrowableArea}>
								{renderGallery()}
							</ThrowableArea>
						</div>
					</BasePadding>
				</div>
				<div className={styles.buttonContainer}>
					<Cta>
						<a href={route} target="_blank" rel="noopener noreferrer">
							<div className={styles.centerText}>{labelCallToAction} <ExternalIcon/></div>
						</a>
					</Cta>
				</div>
				<div className={styles.circleLinesWrapper}>
					<CircleLines />
				</div>
				<div className={styles.circleWrapper}>
					<Circle />
				</div>
			</div>
		</VerticalSpacing>
	)
};

const ProductShowcase = props => {
	const {
		content,
		fetchQuery
	} = props;

	if (content) {
		// If we already have the content for this card (parent query took care of it).
		return (
			<Card {...props.content} />
		)
	} else if (fetchQuery) {
		// If we don't know the content of the card, but have an ID to query it.
		const success = props => {
			return <Card {...props.moduleProductShowcase} />
		};
		return <ConnectedComponent query={ProductShowcaseQuery} variables={{ id: fetchQuery }} success={success} />
	} else {
		return <></>
	}
};

export default ProductShowcase;