import React from "react";
import PropTypes from "prop-types";
import { isNonEmptyString } from "helpers";

import styles from "./styles.css";


const Eyebrow = ({ children }) => {
	return isNonEmptyString(children)
		? (
		<div data-component="Eyebrow" className={styles.eyebrow}>
			{ children }
		</div>
		)
		: "";
};

Eyebrow.propTypes = {
	children: PropTypes.node.isRequired,
};

export default Eyebrow;