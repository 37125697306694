import gql from "graphql-tag";

export const AlbumModuleQuery = gql`
	query AlbumModuleQuery($locale: String!) {
		moduleAlbumCollection(locale: $locale, limit: 1) {
			items {
				collectionSuccessTitle
				collectionsSuccessSubtitle,
                collectionDuplicateTitle,
                collectionDuplicateSubtitle,
                collectionCompleteTitle,
                collectionCompleteSubtitle,
                labelCallToAction
                targetRoute
				footnote
				notCollectedIconDark {
                    url
                    title
                }
                collectedIconDark {
                    url
                    title
                }
				posterCollectionPageTitle
                posterCollectionPageSubtitle
                posterCollectionPageTitleComplete
                posterCollectionPageSubtitleComplete
                labelViewFullPoster
                posterCollectionPageHowItWorks
                posterCollectionPageHowItWorksDescription {
					json
				}
                posterCollectionPageThingsToNote
                posterCollectionPageThingsToNoteDescription {
					json
				}
				assembledPoster {
					url
					title
				}
				shareTitle
				postersCollection(limit: 20) {
					items {
						sys {
							id
						}
						image {
							title
							url
						}
						icon {
							title
							url
						}
						quote
						theme
					}
				}
			}
		}
	}
`;

export const ShareBarQuery = gql`
	query ShareBarQuery($locale: String!) {
		moduleAlbumCollection(locale: $locale, limit: 1) {
			items {
				shareTitle
			}
		}
	}
`;

export const PosterQuery = gql`
	query ModuleAlbumPostersQuery($id: String!) {
        moduleAlbumPoster(id: $id) {
			sys {
				id
			}
			image {
				title
				url
			}
			icon {
				title
				url
			}
            quote
        }
	}
`;