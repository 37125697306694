import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { isNonEmptyArray, isNonEmptyString, removeFalsyValues, useGlobalState } from "helpers";
import Carousel from "components/Carousel";
import Heading from "components/Heading";
import Image from "components/Image";
import PrecisionScroller from "components/PrecisionScroller";

import Arrow from "globals/images/carousel-arrow.svg";
import styles from "./styles.css";

const IMAGE_CARD_GAP = 20;

const StatCarousel = ({ stats, images }) => {
	const hasImages = isNonEmptyArray(images);
	const numStats = isNonEmptyArray(stats) ? stats.length : 0;
	const useGradients = hasImages && numStats > 0;
	const carouselType = "StorytellingImages";
	const [ translationIndex, setTranslationIndex ] = useState(0);
	const [ selectedTheme ] = useGlobalState("selectedTheme");

	const onLeftArrowClick = () => {
		const newIndex = translationIndex - 1;

		if (newIndex >= 0) {
			setTranslationIndex(newIndex);
		}
	};

	const onRightArrowClick = () => {
		const newIndex = translationIndex + 1;

		if (newIndex < numStats) {
			setTranslationIndex(newIndex);
		}
	};

	return (
		<div data-component="StoryTelling\StatCarousel" className={styles.statCarousel}>
			{hasImages
				?
				<Carousel type={carouselType} cardGap={IMAGE_CARD_GAP}>
					{removeFalsyValues(stats).map((stat, index) => {
						const currentImage = index in images ? images[index] : null;

						return (isNonEmptyString(stat.value) || isNonEmptyString(stat.labelStat))
						?
						(
							<div key={index} className={styles.statCardWrapper}>
								<div className={`${styles.statCard} ${styles.imageCard}`}>
									{currentImage &&
									<Image useBackgroundImage url={currentImage.url} altText={currentImage.title} />
									}

									{currentImage && useGradients &&
									<div className={`${styles.imageOverlay} ${selectedTheme in styles ? styles[selectedTheme] : ""}`} />
									}

									<div className={styles.cardBody}>
										{isNonEmptyString(stat.value) &&
										<div className={styles.value}>{stat?.value}</div>
										}

										{isNonEmptyString(stat.labelStat) &&
										<Heading type="h5" color="White" hasShadow>
											{stat.labelStat}
										</Heading>
										}
									</div>
								</div>
							</div>
						)
						:
						null;
					})}
				</Carousel>
				:
				<div className={styles.statOnlyLayout}>
					<button className={`${styles.arrow} ${styles.leftArrow} ${translationIndex > 0 ? styles.visible : ""}`} onClick={onLeftArrowClick}>
						<Arrow />
					</button>

					<div className={styles.scrollContainer}>
						<div className={styles.statListWrapper}>
							<PrecisionScroller itemSelector="li" currentIndex={translationIndex}>
								<ul className={styles.statList}>
								{stats.map((stat, index) => {
									return (
										<li key={index} className={styles.statCard}>
											<div className={styles.cardBody}>
												{isNonEmptyString(stat?.value) &&
												<div className={styles.value}>{stat?.value}</div>
												}

												{isNonEmptyString(stat?.labelStat) &&
												<Heading type="h5" color="Black">
													{stat.labelStat}
												</Heading>
												}
											</div>
										</li>
									);
								})}
								</ul>
							</PrecisionScroller>
						</div>
					</div>

					<div>
					<button className={`${styles.arrow} ${styles.rightArrow} ${translationIndex < (numStats - 1) ? styles.visible : ""}`} onClick={onRightArrowClick}>
						<Arrow />
					</button>
					</div>
				</div>
			}
		</div>
	);
};

StatCarousel.propTypes = {
	stats: PropTypes.arrayOf(PropTypes.shape({
		labelStat: PropTypes.string,
		value: PropTypes.string
	})),
	images: PropTypes.arrayOf(PropTypes.shape({
		url: PropTypes.string,
		title: PropTypes.string
	}))
};

export default StatCarousel;