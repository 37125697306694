import React, { useState, useEffect } from "react";
import { isNonEmptyArray } from "helpers";

import { StoryTellingQuery } from "schema/fragments/StoryTelling";

import Cta from "components/Cta";
import BasePadding from "components/BasePadding";
import ConnectedComponent from "components/ConnectedComponent";
import Container from "components/Container";
import Heading from "components/Heading";
import StatCarousel from "components/StoryTelling/StatCarousel";
import Tabs from "components/Tabs";
import VerticalSpacing from "components/VerticalSpacing";

import styles from "./styles.css";

const Card = (props) => {
	const {
		title,
		labelCallToAction,
		targetRoute,
		imagesCollection: {
			items: images
		},
		statsCollection: {
			items: stats
		},
		tabsCollection,
		isFirst
	} = props;


	const [ selectedTabIndex, setSelectedTabIndex ] = useState(null);
	const hasStats = isNonEmptyArray(stats);
	const hasImages = isNonEmptyArray(images);

	const numTabs = isNonEmptyArray(tabsCollection?.items) ? tabsCollection.items.length : 0;

	const onTabClick = newIndex => {
		setSelectedTabIndex(newIndex);
	};

	useEffect(() => {
		if (numTabs > 0 && (selectedTabIndex >= numTabs || !selectedTabIndex)) {
			setSelectedTabIndex(0);
		}
	}, [numTabs, selectedTabIndex]);

	return (
		<div data-component="StoryTelling">
			<VerticalSpacing>
				<Container>
					<div className={`${styles.layout} ${isFirst ? styles.addTopMargin : ""}`}>
						{title &&
						<BasePadding mobile={{ left: false, right: true }}>
							<div className={`${(hasStats || hasImages) ? styles.mobileTitle : styles.mobileTitleLeftAligned} ${(numTabs > 1) ? styles.mediumTitleMargin : styles.smallTitleMargin}`}>
									<Heading type="h2">{title}</Heading>
							</div>
						</BasePadding>
						}

						{(hasStats || hasImages) &&
						<div className={styles.statRow}>
							<StatCarousel stats={stats} images={images} />
						</div>
						}

						<div className={styles.body}>
							<BasePadding mobile={{ left: false, right: true }}>
								<div className={styles.flexContainer}>
									{title &&
									<div className={styles.desktopTitle}>
										<Heading type="h2">{title}</Heading>
									</div>
									}

									<div className={styles.contentContainer}>
										<div className={styles.tabWrapper}>
											<Tabs tabs={tabsCollection?.items || []} onTabClick={onTabClick} selectedIndex={selectedTabIndex}/>
										</div>

										{(targetRoute && labelCallToAction) &&
										<div className={styles.buttonContainer}>
											<Cta hasBorder>
												<a href={targetRoute} target="_blank" rel="noopener noreferrer" >{labelCallToAction}</a>
											</Cta>
										</div>
										}
									</div>
								</div>
							</BasePadding>
						</div>
					</div>
				</Container>
			</VerticalSpacing>
		</div>

	)
};

const StoryTelling = props => {

	const {
		content,
		fetchQuery
	} = props;

	if (content) {
		// If we already have the content for this card (parent query took care of it).
		return (
			<Card {...props} />
		)
	} else if (fetchQuery) {
		// If we don't know the content of the card, but have an ID to query it.
		const success = props => {
			return <Card {...props.moduleStoryTelling} />
		};

		return <ConnectedComponent query={StoryTellingQuery} variables={{ id: fetchQuery }} success={success} errorPolicy="ignore" />
	} else {
		return <></>
	}
};

export default StoryTelling;