import gql from "graphql-tag";

const {
	REACT_APP_PREVIEW_API: PREVIEW_API
} = process.env;

export const VideoCard = gql`
    query VideoCard($locale: String!, $id: String!) {
        video(preview: ${PREVIEW_API === "true"}, locale: $locale, id: $id) {
            title
            routeSlug
            publishedDate
            category {
                name
                routeSlug
            }
            featuredVideo
            subtitle
            synopsis
            copy {
                json
            }
            excludeFromFeed
        }
    }
`;