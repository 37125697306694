import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import moment from "moment";
import { isNonEmptyString, isNonEmptyArray } from "helpers";
import BasePadding from "components/BasePadding";
import Container from "components/Container";
import Eyebrow from "components/Eyebrow";
import Heading from "components/Heading";
import Image from "components/Image";
import Lightbox from "components/Lightbox";
import VerticalSpacing from "components/VerticalSpacing";

import styles from "./styles.css";


const Gallery = (props) => {
	const {
		content: {
			publishedDate,
			title,
			description,
			category: {
				name: categoryName
			},
			galleryCollection: {
				items: galleryImages
			}
		}
	} = props;

	const [ isLightboxOpen, setIsLightboxOpen ] = useState(false);
	const [ lightboxIndex, setLightboxIndex ] = useState(0);
	const [dividedImages, setDividedImages] = useState(null);
	const subcategoryKey = `subcategory${categoryName}Topic`;
	const subcategoryLabel = subcategoryKey in props.content ? props.content[subcategoryKey] : "";
	const hasTitle = isNonEmptyString(title);
	const hasDescription = isNonEmptyString(description);
	const formattedPublishedDate = moment(publishedDate).format("ll");

	const divideImages = () => {
		const numCols = window.innerWidth >= 768 ? 3 : 2;
		const dividedImages = Array(numCols).fill().map(() => []);

		galleryImages.forEach((image, index) => {
			const col = index % numCols;
			dividedImages[col].push({
				...image,
				galleryIndex: index
			});
		});

		setDividedImages(dividedImages);
	};

	const onImageClick = index => {
		setLightboxIndex(index);
		setIsLightboxOpen(true);
	};

	const onLightboxClose = () => {
		setIsLightboxOpen(false);
	};

	useEffect(() => divideImages(), []); /* todo: this needs to re-run after resize events */

	return (
		<article data-component="Post\Gallery" className={styles.gallery}>
			<Container>
				<VerticalSpacing>
					<div className={styles.body}>
						<BasePadding>
							{isNonEmptyString(subcategoryLabel) &&
							<div className={styles.eyebrow}>
								<Eyebrow>{subcategoryLabel}</Eyebrow>
							</div>
							}

							<div className={styles.titleWrapper}>
								{hasTitle && <div className={styles.title}><Heading type="h1">{title}</Heading></div>}
								{hasDescription && <div className={styles.description}>{description}</div>}
								<div className={styles.date}>{formattedPublishedDate}</div>
							</div>

							<div className={styles.galleryWrapper}>
							{isNonEmptyArray(dividedImages) && dividedImages.map((columnImages, colIndex) => {
								return (
									<div className={`${styles.galleryColumn} ${styles[`col-${colIndex + 1}`]}`} key={`column-${colIndex}`}>
										{isNonEmptyArray(columnImages) && columnImages.map((image, cellIndex) => {
											return (
												<button className={styles.galleryButton} onClick={() => onImageClick(image.galleryIndex)} key={`cell-${cellIndex}`}>
													<div className={styles.galleryCell}>
														<Image url={image.url} altText={image.title} />
													</div>
												</button>
											);
										})}
									</div>
								);
							})}
							</div>
						</BasePadding>
					</div>
				</VerticalSpacing>
			</Container>
			<Lightbox isOpen={isLightboxOpen} images={galleryImages} onClose={onLightboxClose} startingIndex={lightboxIndex} />
		</article>
	);
};

Gallery.propTypes = {
	content: PropTypes.shape({
		publishedDate: PropTypes.string,
		title: PropTypes.string,
		description: PropTypes.string,
		category: PropTypes.shape({
			name: PropTypes.string
		}),
		galleryCollection: PropTypes.shape({
			items: PropTypes.arrayOf(
				PropTypes.shape({
					url: PropTypes.string,
					title: PropTypes.string,
					description: PropTypes.string
				})
			)
		})
	})
};

export default Gallery;