import React, { useState, useEffect, useRef } from "react";
import Hammer from "react-hammerjs";
import { Motion, spring } from "react-motion";
import { isNonEmptyString } from "helpers";

import styles from "./styles.css";

const glideSpring = dt => spring(dt, { stiffness: 150, damping: 40 });
const reboundSpring = dt => spring(dt, { stiffness: 150, damping: 15 });

const ThrowableArea = ({ children, disabled, className, fullWidth }) => {
	const areaRef = useRef(null);
	const hammerOptions = { requireFailure: true, enable: !disabled };

	const [ isDragging, setIsDragging ] = useState(false);
	const [ x0, setX0 ] = useState(0);
	const [ xt, setXt ] = useState(0);
	const [ vxt, setVxt ] = useState(null);
	const [ shouldBounceX, setShouldBounceX ] = useState(false);
	const [ offsetX, setOffsetX ] = useState(0);

	const calculateBoundingDimensions = () => {
		const current = areaRef.current;
		const boundingClientRect = current.getBoundingClientRect();
		const overflowX = current.scrollWidth + offsetX - window.innerWidth;

		return {
			leftEdge: 0,
			rightEdge: 0 - overflowX,
			shouldBounceLeft: boundingClientRect.x > 0,
			shouldBounceRight: boundingClientRect.x < (0 - overflowX)
		};
	};

	const handlePanStart = () => {
		setIsDragging(true);
		setX0(xt);
		setShouldBounceX(false);
	};

	const handlePanEnd = () => {
		setIsDragging(false);
	};

	const calculateStoppingDistance = vt => (((vt * 1000) ^ 2) / (2 * .4 * 9.8));

	const startGlide = (deltaX) => {
		const { leftEdge, rightEdge } = calculateBoundingDimensions();

		const calculateFinalX = () => {
			const finalX = x0 + deltaX + calculateStoppingDistance(vxt);

			if (finalX > leftEdge) {
				setShouldBounceX(true);
				return leftEdge;
			} else if (finalX < rightEdge) {
				setShouldBounceX(true);
				return rightEdge;
			}

			return finalX;
		};

		setXt(calculateFinalX());
	};

	const handlePan = ({ isFinal, velocityX, deltaX}) => {
		const {
			leftEdge,
			rightEdge,
			shouldBounceLeft,
			shouldBounceRight,
		} = calculateBoundingDimensions();

		const shouldBounceX = shouldBounceLeft || shouldBounceRight;

		let bounceTargetX;

		if (shouldBounceX) {
			bounceTargetX = shouldBounceLeft ? leftEdge : rightEdge;
		}

		if (!isFinal) {
			setShouldBounceX(shouldBounceX);
			setVxt(velocityX);
			setXt(shouldBounceX ? bounceTargetX : x0 + deltaX);

			startGlide(deltaX);
		}
	};

	const renderMotionArea = style => {
		const styleObject = style !== false ? { transform: `translate3d(${style.x}px, 0, 0)` } : {};

		return (
			<div ref={areaRef} className={styles.motionArea} style={styleObject}>
				{children}
			</div>
		);
	};

	const setInitialPosition = () => {
		const current = areaRef.current;
		const boundingClientRect = current.getBoundingClientRect();
		const initialX = Math.floor(boundingClientRect.x);

		if (fullWidth) {
			setOffsetX(initialX);
		}
	};

	useEffect(setInitialPosition, []);

	const crunchClasses = () => {
		const incomingClass = isNonEmptyString(className) ? className : "";
		const isDraggingClass = isDragging ? styles.isDragging : "";

		return `${styles.throwableArea} ${isDraggingClass} ${incomingClass}`
	};

	return (
		<div data-component="ThrowableArea" className={crunchClasses()}>
			<Hammer options={hammerOptions} onPanStart={handlePanStart} onPan={handlePan} onPanEnd={handlePanEnd} direction="DIRECTION_ALL">
				<div>
					<Motion defaultStyle={{ x: xt }} style={{
						x: shouldBounceX ? reboundSpring(xt) : glideSpring(xt)
					}}>
						{style => renderMotionArea(style)}
					</Motion>
				</div>
			</Hammer>
		</div>
	);
};

export default ThrowableArea;