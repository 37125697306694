import React from "react";
import PropTypes from "prop-types";

import styles from "./styles.css";

const Heading = props => {
	const {
		children,
		color,
		type: HeadingType,
		transformUppercase,
		hasShadow
	} = props;

	const crunchClasses = () => {
		const headingColor = color ? styles[`color${color}`] : "";
		const headingTransformUppercase = transformUppercase ? styles.transformUppercase : "";
		const headingHasShadow = hasShadow ? styles.hasShadow : "";

		return `${styles.heading} ${headingColor} ${headingTransformUppercase} ${headingHasShadow}`;
	};

	return (
		<HeadingType data-component="heading" className={crunchClasses()}>
			{children}
		</HeadingType>
	);
};

Heading.propTypes = {
	type: PropTypes.string.isRequired,
	color: PropTypes.string,
	transformUppercase: PropTypes.bool,
	children: PropTypes.oneOfType([PropTypes.string, PropTypes.node])
};

export default Heading;