import React from "react";
import PropTypes from "prop-types";
import moment from "moment";
import { isNonEmptyString, isNonEmptyArray } from "helpers";
import AuthorList from "components/Post/AuthorList";
import BasePadding from "components/BasePadding";
import Container from "components/Container";
import Eyebrow from "components/Eyebrow";
import Heading from "components/Heading";
import Image from "components/Image";
import RelatedPosts from "components/RelatedPosts";
import RichText from "components/RichText";
import Subheading from "components/Subheading";
import Synopsis from "components/Synopsis";
import VerticalSpacing from "components/VerticalSpacing";
import Video from "components/Video";

import styles from "./styles.css";


const Article = props => {
	const {
		content: {
			copy,
			featuredImage,
			featuredVideo,
			publishedDate,
			subtitle,
			synopsis,
			title,
			authorCollection: {
				items: authors
			},
			category: {
				name: categoryName
			}
		}
	} = props;

	const subcategoryKey = `subcategory${categoryName}Topic`;
	const subcategoryLabel = subcategoryKey in props.content ? props.content[subcategoryKey] : "";
	const hasTitle = isNonEmptyString(title);
	const hasSubtitle = isNonEmptyString(subtitle);
	const formattedPublishedDate = moment(publishedDate).format("ll");
	const hasVideo = isNonEmptyString(featuredVideo);

	return (
		<article data-component="Post\Article" className={styles.article}>
			<Container>
				<VerticalSpacing>
					<div style={{ position: "relative" }}>
						<BasePadding mobile={{ disabled: true }}>
							<div className={styles.featuredImage}>
								{hasVideo
									? <Video url={featuredVideo} />
									: <Image url={featuredImage.url} altText={featuredImage.title} aspectRatio="16x9" />
								}
							</div>
						</BasePadding>

						<BasePadding>
							<div className={styles.articleBody}>
								{isNonEmptyString(subcategoryLabel) &&
								<div className={styles.eyebrow}>
									<Eyebrow>{subcategoryLabel}</Eyebrow>
								</div>
								}

								<div className={styles.titleWrapper}>
									{hasTitle && <div className={styles.title}><Heading type="h1">{title}</Heading></div>}
									{hasSubtitle && <div className={styles.subtitle}><Subheading>{subtitle}</Subheading></div>}
									<div className={styles.date}>{formattedPublishedDate}</div>
								</div>

								{isNonEmptyString(synopsis) &&
								<div className={styles.synopsis}>
									<Synopsis text={synopsis} />
								</div>
								}

								<div className={styles.copy}>
									<RichText data={copy} />

									{isNonEmptyArray(authors) && 
									<div className={styles.footer}>
										<hr />
										<AuthorList authors={authors} />
									</div>
									}
								</div>

							</div>
						</BasePadding>
					</div>
				</VerticalSpacing>
			</Container>
		</article>
	);
};

Article.propTypes = {
	content: PropTypes.shape({
		copy: PropTypes.object,
		featuredImage: PropTypes.object,
		featuredVideo: PropTypes.string,
		publishedDate: PropTypes.string,
		subtitle: PropTypes.string,
		synopsis: PropTypes.string,
		title: PropTypes.string,
		authorCollection: PropTypes.shape({
			items: PropTypes.arrayOf(PropTypes.object)
		}),
		category: PropTypes.shape({
			name: PropTypes.string
		})
	})
};

export default Article;