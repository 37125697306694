import { isNonEmptyString, isObjectWithKey } from "./utils";

let selectedLocaleId = null;

export const supportedLanguages = [
  { id: "en", routeCode: "en", apiCode: "en-US", display: "English", shortDisplay: "Eng" },
  { id: "pt", routeCode: "pt", apiCode: "pt", display: "Portugues", shortDisplay: "Por" },
  { id: "es", routeCode: "es", apiCode: "es", display: "Español", shortDisplay: "Esp" }
];

export const communityRouteSlugs = ["community", "comunidad", "comunidade"];

export const cultureRouteSlugs = ["culture", "cultura"];

export const footballRouteSlugs = ["football", "futebol", "futbol"];

export const setLocaleId = l => selectedLocaleId = l;

export const getLocaleId = () => selectedLocaleId;

export const getLanguageByRouteId = languageId => supportedLanguages.find(lang => lang.id === languageId);

export const getLanguageByRouteCode = routeCode => supportedLanguages.find(lang => lang.routeCode === routeCode);

export const storeLocale = localeId => {
  if (getLanguageByRouteId(localeId)) {
    localStorage.setItem("defaultLocale", localeId);
  }
};

export const getStoredLocale = () => {
  return localStorage.getItem("defaultLocale");
};

export const determineDefaultLanguage = () => {
  const joinedLanguages = supportedLanguages.map(language => language.id).join("|");
  const regionalLanguageCodeRegex = new RegExp(`^(${joinedLanguages})-[a-z]{2}$`);
  const languageCode = (getStoredLocale() || navigator.language || "en").toLowerCase();
  const isRegionalCode = regionalLanguageCodeRegex.test(languageCode);

  return isRegionalCode
    ? languageCode.substr(0, 2)
    : "en"; //Bug was raised that when your locale is not en, es or pt, this fails. Setting it to fall back to "en" if outside one of those regions
};

export const DEFAULT_LANGUAGE_ID = determineDefaultLanguage();

export const getSelectedLanguage = () => {
  return getLanguageByRouteCode(selectedLocaleId);
};

export const getValueFromSelectedLanguage = key => {
  const selectedLanguage = getSelectedLanguage();

  return isObjectWithKey(selectedLanguage, key) ? selectedLanguage[key] : undefined;
};

export const getValueFromLanguageById = (key, languageId) => {
  const language = getLanguageByRouteId(languageId);

  return isObjectWithKey(language, key) ? language[key] : undefined;
};

export const getSelectedLanguageRouteCode = () => getValueFromSelectedLanguage("routeCode");

export const getSelectedLanguageApiCode = () => getValueFromSelectedLanguage("apiCode");

export const isRouteCodeSupported = routeCode => supportedLanguages.findIndex(supportedLanguage => supportedLanguage.routeCode === routeCode) > -1;

export const getCurrentLocationForLanguageId = languageId => {
  const currentRouteCode = getSelectedLanguageRouteCode();
  const newRouteCode = getValueFromLanguageById("routeCode", languageId);
  const regex = new RegExp(`^\\/${currentRouteCode}`); // todo: this should probably be stricter
  const path = window.location.pathname;

  return path.replace(regex, `/${newRouteCode}`);
};

export const getEnglishCategorySlug = categorySlug => {
  if (communityRouteSlugs.includes(categorySlug)) {
    return "community";
  }

  if (cultureRouteSlugs.includes(categorySlug)) {
    return "culture";
  }

  if (footballRouteSlugs.includes(categorySlug)) {
    return "football";
  }

  return categorySlug;
};