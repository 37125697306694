import gql from "graphql-tag";

const {
	REACT_APP_PREVIEW_API: PREVIEW_API
} = process.env;

export const GalleryCard = gql`
	query GalleryCard($locale: String!, $id: String!) {
		gallery(preview: ${PREVIEW_API === "true"}, locale: $locale, id: $id) {
			title
            description
			routeSlug
			publishedDate
            subcategoryCommunityTopic
            subcategoryCultureTopic
            subcategoryFootballTopic
			galleryCollection {
				... on AssetCollection {
					items {
                        contentType
                        title
                        description
                        url
						height
						width
					}
				}
			}
            category {
                name
            }
		}
	}
`;
