import { ApolloLink } from "apollo-link";
import { isNonEmptyString, getSelectedLanguageApiCode } from "helpers";

export const LocaleLink = new ApolloLink((operation, forward) => {
  operation.variables = {
    locale: getSelectedLanguageApiCode(),
    ...operation.variables,
  };

  if (operation.variables.locale === "en" || !isNonEmptyString(operation.variables.locale)) {
    operation.variables.locale = "en-US";
  }

  return forward(operation);
});