import React, { useState } from "react";
import { GetSubscriptionModule } from "schema/subscription";
import { useDictionaryContent } from "hooks/shell";
import { getFirstCollectionItem, useGlobalState } from "helpers";
import ConnectedComponent from "components/ConnectedComponent";
import EmailCollection from "components/Forms/EmailCollection";

import styles from "./styles.css";


const Subscription = () => {
	const [ dictionaryContent ] = useDictionaryContent();
	const [ isEmailCaptured, setEmailCaptured ] = useState(false);
	const [ selectedTheme ] = useGlobalState("selectedTheme");

	const onEmailSubmitted = () => {
		setEmailCaptured(true);
	};

	const success = ({ moduleSubscriptionCollection: collection }) => {
		const {
			confirmationMessage,
			description,
			labelCallToAction,
			labelEmptyText,
			labelGenericError,
			labelInvalidEmail,
			labelEmailInUse,
			submitEndpoint,
			title
		} = getFirstCollectionItem(collection) || {};

		return (
			<div data-component="Subscription" className={`${styles.subscription} ${isEmailCaptured ? styles.captured : ""} ${selectedTheme in styles ? styles[selectedTheme] : ""}`}>
				<div className={styles.heading}>{title}</div>
				{description && <div className={styles.description}>{description}</div>}

				{isEmailCaptured
					? <div className={styles.confirmation}>{confirmationMessage}</div>
					:
					<EmailCollection
						onSubmit={onEmailSubmitted}
						endpoint={submitEndpoint}
						labels={{
							emailInvalid: labelInvalidEmail,
							emailDuplicate: labelEmailInUse,
							emailRequired: dictionaryContent?.labelEmailRequired,
							emailPlaceholder: labelEmptyText,
							submitButton: labelCallToAction,
							submitConfirmation: confirmationMessage,
							submitGenericError: labelGenericError
						}}
					/>
				}
			</div>
		);
	};

	return <ConnectedComponent query={GetSubscriptionModule} success={success} />
};

export default Subscription;