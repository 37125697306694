import React from "react";
import PropTypes from "prop-types";
import {
	isNonEmptyArray,
	supportedLanguages,
	getSelectedLanguage,
	getCurrentLocationForLanguageId,
	storeLocale
} from "helpers";
import { useHeaderContent } from "hooks/shell";

import ArrowThick from "./images/dropdown-arrow-thick.svg";
import ArrowThin from "./images/dropdown-arrow-thin.svg";
import styles from "./styles.css";


const LanguageFilter = ({ inFooter }) => {
	const [ content ] = useHeaderContent();
	const { labelLanguage } = content || {};

	const selectedLanguage = getSelectedLanguage();
	const numLanguages = isNonEmptyArray(supportedLanguages) ? supportedLanguages.length : 0;

	const handleChange = ev => {
		const languageId = ev.target.value;
		window.location = "/" + languageId;
		storeLocale(languageId);
	};

	return numLanguages > 0
		? (
			<div className={`${styles.languageSelector} ${(inFooter ? styles.footerVariant : "")}`}>
				<label htmlFor="language" className={styles.label}>{labelLanguage}</label>
				<div className={styles.selectWrapper}>
					{inFooter ? <ArrowThin/> : <ArrowThick/>}
					<select className={styles.select} id="language" onChange={handleChange} value={selectedLanguage ? selectedLanguage.id : ""}>
						{supportedLanguages.map(language => (
							<option value={language.id} key={language.id}>{language.shortDisplay}</option>
						))}
					</select>
				</div>
			</div>
		)
		: "";
};

LanguageFilter.propTypes = {
	inFooter: PropTypes.bool
};

export default LanguageFilter;