import gql from "graphql-tag";

const {
	REACT_APP_PREVIEW_API: PREVIEW_API
} = process.env;


export const GetCategoryFilters = gql`
	query GetCategoryFilters($locale: String!, $categorySlug: String!) {
        categoryCollection(preview: ${PREVIEW_API === "true"}, locale: $locale, limit: 1, where: {routeSlug: $categorySlug}) {
            items {
                name
                routeSlug
                sys {
                    id
                }
                filterGroupsCollection(limit: 5) {
                    items {
                        title
                        targetField
                        sys {
                            id
                        }
                        filtersCollection(limit: 8) {
                            items {
                                title
                                sys {
                                    id
                                }
                            }
                        }
                    }
                }
            }
        }
    }
`;