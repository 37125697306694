import React from "react";

import ArticleFeedCard from "components/ArticleFeedCard";
import ArticleFeedCardFeatured from "components/ArticleFeedCardFeatured";
import GalleryFeedCard from "components/GalleryFeedCard";
import Instagram from "components/Instagram";
import ProductShowcase from "components/ProductShowcase";
import Poster from "components/Poster";
import StoryTelling from "components/StoryTelling";
import VideoFeedCard from "components/VideoFeedCard";

const ComponentComposer = ({ componentName, id, content, isFirst, ...componentProps }) => {
	const componentList = {
		Article: isFirst ? ArticleFeedCardFeatured : ArticleFeedCard,
		Gallery: GalleryFeedCard,
		Video: VideoFeedCard,
		ModuleProductShowcase: ProductShowcase,
		ModuleInstagram: Instagram,
		ModuleAlbumPoster: Poster,
		ModuleStoryTelling: StoryTelling
	};
	const ComponentType = componentList[componentName];

	return <ComponentType fetchQuery={id} content={content} isFirst={isFirst} {...componentProps} />
};

export default ComponentComposer;