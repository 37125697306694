import React, { useState } from "react";
import PropTypes from "prop-types";
import { isFunction, isNonEmptyString, isNonEmptyArray } from "helpers";
import PrecisionScroller from "components/PrecisionScroller";
import VerticalSpacing from "components/VerticalSpacing";

import Arrow from "globals/images/carousel-arrow.svg"
import styles from "./styles.css";

const Tabs = ({ tabs, onTabClick, selectedIndex: selected }) => {
	const selectedTabContent = tabs[selected]?.description;
	const [ translationIndex, setTranslationIndex ] = useState(null);
	const numTabs = isNonEmptyArray(tabs) ? tabs.length : 0;

	const handleTabClick = index => {
		if (isFunction(onTabClick)) {
			onTabClick(index);
		}
	};

	const onLeftArrowClick = () => {
		const newIndex = translationIndex - 1;
		if (newIndex >= 0) {
			setTranslationIndex(newIndex);
			handleTabClick(newIndex);
		}
	};

	const onRightArrowClick = () => {
		const newIndex = translationIndex + 1;
		if (newIndex < numTabs) {
			setTranslationIndex(newIndex);
			handleTabClick(newIndex);
		}
	};

	return (
		<>
			<div data-component="Tabs" className={styles.tabs}>
				{(numTabs > 1) && <div className={styles.tabRow}>
					<nav className={styles.nav}>
						<button className={`${styles.arrow} ${styles.leftArrow} ${translationIndex > 0 ? styles.visible : ""}`} onClick={onLeftArrowClick}>
							<Arrow />
						</button>

						<div className={styles.scrollContainer}>
							<div className={styles.tabListWrapper}>
								<PrecisionScroller currentIndex={translationIndex} itemSelector="li">
									<ul role="tablist" aria-label="Tab Row">
										{tabs.map((tab, index) => {
											const isSelectedTab = index === selected;

											return (
												<li key={index} className={styles.navItem} role="tab">
													<button
														className={`${styles.tab} ${isSelectedTab ? styles.selected : ""}`}
														disabled={isSelectedTab}
														onClick={() => handleTabClick(index)}
														aria-selected={isSelectedTab}
														aria-controls={`${tab.labelTab} tab`}
														tabIndex="0"
													>
													<span>{tab.labelTab}</span>
													</button>
												</li>
											);
										})}
									</ul>
								</PrecisionScroller>
							</div>
							<div className={styles.line} />
						</div>

						<button className={`${styles.arrow} ${styles.rightArrow} ${translationIndex < (numTabs - 1) ? styles.visible : ""}`} onClick={onRightArrowClick}>
							<Arrow />
						</button>
					</nav>
				</div>}

				<div className={styles.tabContent}>
					{isNonEmptyString(selectedTabContent) && <p>{selectedTabContent}</p>}
				</div>
			</div>
		</>
	);
};

Tabs.propTypes = {
	tabs: PropTypes.arrayOf(
		PropTypes.shape({
			labelTab: PropTypes.string,
			description: PropTypes.string
		})
	),
	selectedIndex: PropTypes.number,
	onTabClick: PropTypes.func.isRequired,
};

Tabs.defaultProps = {
	transitionId: ""
};

export default Tabs;