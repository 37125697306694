import React, { useState } from "react";
import { PostQuery } from "schema/post";
import { Redirect } from "@reach/router";
import { isNonEmptyArray, getLocaleId, useGlobalState } from "helpers";
import Article from "components/Post/Article";
import ConnectedComponent from "components/ConnectedComponent";
import Gallery from "components/Post/Gallery";
import RelatedPosts from "components/RelatedPosts";

const Post = ({ post: postSlug }) => {
	const [ , setPageTitle ] = useGlobalState("pageTitle");

	const success = ({ articleCollection, videoCollection, galleryCollection }) => {
		let postData;
		let postContent;
		let postType = undefined;

		if (isNonEmptyArray(articleCollection.items)) {
			postData = articleCollection.items[0];
			postContent = <Article content={postData} />;
			postType = "article";
		}

		if (isNonEmptyArray(videoCollection.items)) {
			postData = videoCollection.items[0];
			postContent = <Article content={postData} />;
			postType = "video";
		}

		if (isNonEmptyArray(galleryCollection.items)) {
			postData = galleryCollection.items[0];
			postContent = <Gallery content={postData} />;
			postType = "gallery";
		}

		setPageTitle(`${postData?.title} | Neymar Jr.`);

		const locale = getLocaleId();
		return postContent
			?
			(
				<div data-component="Container\Post">
					{postContent}
					<RelatedPosts post={postSlug} postType={postType} />
				</div>
			)
			: <Redirect from="/" to={`/${locale}/404`} default noThrow/>;
	};

	return <ConnectedComponent query={PostQuery} variables={{ slug: postSlug }} success={success} />
};

export default Post;