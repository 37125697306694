import { useGlobalState } from "helpers";

export const useCategoryFilters = categoryKey => {
	const [ filters, setState ] = useGlobalState("feedFilters");

	const setCategoryFilters = (newFilters) => {
		const updatedState = {
			...filters,
			[categoryKey]: newFilters
		};

		setState(updatedState);
	};

	const returnValue = categoryKey in filters ? filters[categoryKey] : {};

	return [ returnValue, setCategoryFilters ];
};