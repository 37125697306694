import React from "react";
import PropTypes from "prop-types";
import { useDictionaryContent } from "hooks/shell";

import { isFunction } from "helpers";

import styles from "./styles.css";

const HamburgerHelper = ({ color, onClick }) => {

	const [ dictionaryContent ] = useDictionaryContent();

	return (	
		<button className={`${styles.hamburgerHelper} ${color === "white" ? styles.white : ""}`} onClick={isFunction(onClick) ? onClick : ""} aria-label={dictionaryContent.labelOpen}>
			<span></span>
			<span></span>
			<span></span>
		</button>
	)
};

HamburgerHelper.propTypes = {
	onClick: PropTypes.func,
	color: PropTypes.string
};

export default HamburgerHelper;