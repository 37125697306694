import React  from "react";
import PropTypes from "prop-types";
import { isNonEmptyString } from "helpers";

import styles from "./styles.css";


const Image = ({ url, altText, useBackgroundImage, fillContainer, aspectRatio, imageAlignment, className }) => {
	const alt = isNonEmptyString(altText) ? altText : "";
	const aspectRatioClass = isNonEmptyString(aspectRatio) ? `aspect${aspectRatio}` : null;
	const hasAspectRatio = isNonEmptyString(aspectRatioClass) && aspectRatioClass in styles;

	const renderImage = () => {
		return (
			useBackgroundImage
				?
				<div className={`${styles.backgroundImage}`} style={{ backgroundImage: `url(${url})` }} title={altText}>
					<img src={url} alt={alt} className={styles.imageTag} draggable={false} />
				</div>
				:
				<img src={url} alt={alt} className={`${styles.imageTag} ${styles.standaloneImage}`} draggable={false} height="100%"/>
		);
	};

	return (
		<div data-component="Image" className={`${styles.image} ${fillContainer ? styles.fillContainer : ""} ${className}`}>
			{hasAspectRatio
				?
				<div className={`${hasAspectRatio ? styles.aspectWrapper : ""} ${hasAspectRatio ? styles[aspectRatioClass] : ""}`}>
					<div className={`${styles.innerAspectWrapper} ${imageAlignment in styles ? styles[imageAlignment] : ""}`} >
						{renderImage()}
					</div>
				</div>
				: renderImage()
			}
		</div>
	);
};

Image.propTypes = {
	url: PropTypes.string.isRequired,
	altText: PropTypes.string,
	useBackgroundImage: PropTypes.bool,
	fillContainer: PropTypes.bool,
	aspectRatio: PropTypes.oneOf(["1x1", "3x2", "3x4", "4x3", "7x5", "16x9"]),
	imageAlignment: PropTypes.oneOf(["left", "right", "center"])
};

export default Image;