import gql from "graphql-tag";

export const InstagramCard = gql`
    query InstagramCard($locale: String!, $id: String!) {
		moduleInstagram(locale: $locale, id: $id) {
			username
			eyebrow
			labelCallToAction
			targetRoute
			apiToken
			userId
		}
    }
`;