import React from "react";
import { VideoCard } from "schema/fragments/Video";
import Card from "components/ArticleFeedCard";
import ConnectedComponent from "components/ConnectedComponent";

const VideoFeedCard = props => {
	const {
		content,
		fetchQuery
	} = props;

	if (content) {
		// If we already have the content for this card (parent query took care of it).
		return <Card content={{...props.content}} />;
	} else if (fetchQuery) {
		// If we don't know the content of the card, but have an ID to query it.
		const success = props => <Card content={{...props.video}} />;

		return <ConnectedComponent query={VideoCard} variables={{ id: fetchQuery }} success={success} />
	} else {
		return <></>
	}
};

export default VideoFeedCard;