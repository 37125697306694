import React, { useRef, useState, useEffect } from "react";
import PropTypes from "prop-types";
import Mailchimp from "react-mailchimp-form";
import { isNonEmptyString, isFunction } from "helpers";
import FormError from "components/Forms/Elements/FormError";

import styles from "./styles.css";


const EmailCollection = ({ labels, onSubmit, endpoint }) => {
	const ref = useRef(null);
	const [errorMessage, setErrorMessage] = useState(false);
	const [status, setStatus] = useState(false);

	const fields = [
		{
			name: "EMAIL",
			placeholder: labels?.emailPlaceholder,
			type: 'email',
			required: true,
		}
	];
	const messages = {
		button: labels?.submitButton || "Submit",
		duplicate: labels?.emailDuplicate || "Email already in use",
		empty: labels?.emailRequired || "Email is required",
		error: labels?.submitGenericError || "Something went wrong",
		sending: "sending",
		success: "success"
	};

	const sniffStatus = message => {
		for (let key in messages) {
			if (messages.hasOwnProperty(key) && messages[key] === message) {
				return key;
			}
		}

		return false;
	};

	useEffect(() => {
		let observer;

		if (ref?.current) {
			observer = new MutationObserver(() => {
				const message = ref.current.querySelector(".msg-alert")?.textContent;

				if (message !== errorMessage) {
					setErrorMessage(isNonEmptyString(message) ? message : false);
				}

				if (isNonEmptyString(message)) {
					const newStatus = sniffStatus(message);

					if (newStatus !== status) {
						if (newStatus === "success" && isFunction(onSubmit)) {
							onSubmit();
						}

						setStatus(newStatus);
					}
				}
			});

			observer.observe(ref.current, { attributes: true, childList: true, subtree: true });
		}

		return () => observer?.disconnect();
	}, [ref]);

	const displayMessage = isNonEmptyString(errorMessage) && [messages.error, messages.empty, messages.duplicate].includes(errorMessage)
		? errorMessage
		: "";

	return (
		<div data-component="Forms\EmailCollection" ref={ref} className={styles.formContainer}>
			<FormError name="EMAIL" message={displayMessage}>
				<Mailchimp action={endpoint} fields={fields} className={styles.form} messages={messages} />
			</FormError>
		</div>
	);
};

EmailCollection.propTypes = {
	endpoint: PropTypes.string.isRequired,
	onSubmit: PropTypes.func,
	labels: PropTypes.shape({
		emailInvalid: PropTypes.string,
		emailRequired: PropTypes.string,
		emailPlaceholder: PropTypes.string,
		submitButton: PropTypes.string,
		submitConfirmation: PropTypes.string,
		submitGenericError: PropTypes.string
	})
};

export default EmailCollection;