import gql from "graphql-tag";

const {
	REACT_APP_PREVIEW_API: PREVIEW_API
} = process.env;

const infoFields = `
    publishedDate
    routeSlug
    subtitle
    synopsis
    title
`;

const subcategoryFields = `
    subcategoryCommunityTopic,
    subcategoryCultureTopic,
    subcategoryFootballTopic,
`;

const copyFields = `
json
links {
    entries {
        hyperlink {
            ... on Article {
                routeSlug
                category {
                    name
                }
            }
            sys {
                id
            }
        }
    }
    assets {
        hyperlink {
            url,
            title,
            description,
            sys {
                id
            }
        }
        block {
            contentType
            fileName
            title
            description
            url
            height
            width
            sys {
                id
            }
        }
    },
}
`;

const ImageFragment = gql`
    fragment ImageFragment on Asset {
        contentType
        title
        description
        url
    }
`;

const ArticleCopyFragment = gql`
    fragment ArticleCopyFragment on ArticleCopy {
        ${copyFields}
    }
`;

const ArticleCollectionFragment = gql`
    fragment ArticleCollectionFragment on ArticleCollection {
        items {
            authorCollection(limit: 10) {
                items {
                    name
                }
            }
            category {
                name
            }
            ${subcategoryFields}
            copy {
                ...ArticleCopyFragment
            }
            featuredImage {
                ...ImageFragment
            }
            featuredInsetImage {
                ...ImageFragment
            }
            ${infoFields}
        }
    },
    ${ArticleCopyFragment},
    ${ImageFragment}
`;

export const VideoCollectionFragment = gql`
    fragment VideoCollectionFragment on VideoCollection {
        items {
            authorCollection(limit: 10) {
                items {
                    name
                }
            }
            category {
                name
            }
            ${subcategoryFields}
            copy {
                ${copyFields}
            }
            featuredVideo
            ${infoFields}
        }
    },
    ${ArticleCopyFragment},
    ${ImageFragment}
`;

const GalleryCollectionFragment = gql`
    fragment GalleryCollectionFragment on GalleryCollection {
        items {
            category {
                name
            }
            ${subcategoryFields}
            publishedDate
            routeSlug
            title
            description,
            galleryCollection(limit: 100) {
                items {
                    ...ImageFragment
                }
            }
        }
    },
    ${ImageFragment}
`;

export const PostQuery = gql`
    query postQuery($locale: String!, $slug: String!) {
        articleCollection(preview: ${PREVIEW_API === "true"}, locale: $locale, limit: 1, where: {routeSlug: $slug}) {
            ...ArticleCollectionFragment
        }
        galleryCollection(preview: ${PREVIEW_API === "true"}, locale: $locale, limit: 1, where: {routeSlug: $slug}) {
            ...GalleryCollectionFragment
        }
        videoCollection(preview: ${PREVIEW_API === "true"}, locale: $locale, limit: 1, where: {routeSlug: $slug}) {
            ...VideoCollectionFragment
        }
    },
    ${VideoCollectionFragment},
    ${ArticleCollectionFragment},
    ${GalleryCollectionFragment}
`;