import React from "react";
import { BLOCKS, INLINES } from "@contentful/rich-text-types";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import { isNonEmptyArray, isNonEmptyString, getDeepObjectValue, useGlobalState } from "helpers";
import Image from "components/Image";
import PullQuote from "components/PullQuote";
import LocaleLink from "components/LocaleLink";

import styles from "./styles.css";

const RichText = ({ data }) => {

	const [ selectedTheme ] = useGlobalState("selectedTheme");

	const defaultLinkObject = { block: [], inline: [] };
	const assets = data && data.links && data.links.assets
		? { ...defaultLinkObject, ...data.links.assets }
		: defaultLinkObject;
	const entries = data && data.links && data.links.entries
		? { ...defaultLinkObject, ...data.links.entries }
		: defaultLinkObject;

	const extractAssetFromNode = (node, embedType) => {
		const id = getDeepObjectValue(node, "data.target.sys.id");

		return id && isNonEmptyArray(assets[embedType])
			? assets[embedType].find(asset => getDeepObjectValue(asset, "sys.id") === id)
			: undefined;
	};

	const extractEntryFromNode = (node, entryType) => {
		const id = getDeepObjectValue(node, "data.target.sys.id");

		return id && isNonEmptyArray(entries[entryType])
			? entries[entryType].find(entry => getDeepObjectValue(entry, "sys.id") === id)
			: undefined;
	};

	const parsed = !data ? false : documentToReactComponents(data.json,
	{
		renderNode: {
			[BLOCKS.QUOTE]: (node, children) => (
				<div className={`richTextPullQuote ${styles.pullQuote}`}>
					<PullQuote size="xl">{children}</PullQuote>
				</div>
			),
			[BLOCKS.PARAGRAPH]: (node, children) => {
				const content = Array.isArray(node.content) && node.content.length === 1 ? node.content[0] : {};
				const contentIsItalic = isNonEmptyArray(content.marks) && content.marks[0].type === "italic";

				return contentIsItalic
					? <p className={styles.italicParagraph}>{children}</p>
					: <p>{children}</p>;
			},
			[BLOCKS.EMBEDDED_ASSET]: node => {
				const asset = extractAssetFromNode(node, "block");
				const isValidImage = asset && isNonEmptyString(asset.url) && asset.contentType.match(/^image/);
				const isLandscape = asset && asset.width > asset.height;
				const aspectClass = isLandscape ? styles.aspect4x3 : styles.aspect3x4;

				return isValidImage
					? (
						<div className={`embeddedAsset ${styles.blockImageWrapper} ${aspectClass}`}>
							<Image imageAlignment="left" url={asset.url} altText={asset.titleWrapper || ""} aspectRatio={`${isLandscape ? "4x3" : "3x4"}`}/>
							{isNonEmptyString(asset.description) && <div className={styles.caption}>{asset.description}</div>}
						</div>
					)
					: "";
			},
			[INLINES.HYPERLINK]: (node, children) => {
				return <a href={node.data.uri} target="_blank" rel="noopener noreferrer">{children}</a>;
			},
			[INLINES.ASSET_HYPERLINK]: (node, children) => {
				const asset = extractAssetFromNode(node, "hyperlink");

				return asset ? <a href={asset.url} target="_blank" rel="noopener noreferrer">{children}</a> : children;
			},
			[INLINES.ENTRY_HYPERLINK]: (node, children) => {
				const entry = extractEntryFromNode(node, "hyperlink");
				const typename = entry.__typename;
				const categoryName = getDeepObjectValue(entry, "category.name");
				const routeSlug = entry && isNonEmptyString(entry.routeSlug) ? entry.routeSlug : "";

				if (typename === "General" && routeSlug) {
					return <LocaleLink to={`/${routeSlug}`}>{children}</LocaleLink>
				} else if (routeSlug && categoryName) {
					return <LocaleLink to={`/${categoryName.toLowerCase()}/${routeSlug}`}>{children}</LocaleLink>
				}
				
				return children;
			}
		}
	});

	return parsed
		?
		(
		<div data-component="RichText">
			<div className={`${styles.richText} ${selectedTheme in styles ? styles[selectedTheme] : ""}`}>{parsed}</div>
		</div>
		)
		: "";
};

export default RichText;