import React, { useRef, useEffect, useState } from "react";
import PropTypes from "prop-types"
import Popover, { ArrowContainer } from "react-tiny-popover";
import { connect } from "formik";
import { isNonEmptyString } from "helpers";

import styles from "./styles.css";


const FormError = ({ name, children, appendToBody, message, formik: { errors = {} } }) => {
	const ref = useRef(null);
	const [ isReady, setIsReady ] = useState(false);

	const getMessage = () => {
		if (isNonEmptyString(message)) {
			return message;
		}

		return name in errors ? errors[name] : "";
	};

	useEffect(() => {
		if (ref?.current) {
			setIsReady(true);
		}
	}, [ref, setIsReady]);

	return (
		<div ref={ref} data-component="Forms\Elements\FormError" className={styles.formError}>
			{isReady &&
			<Popover
				contentDestination={!appendToBody && ref.current ? ref.current : null}
				containerClassName={`${styles.popover} ${!appendToBody ? styles.appendToContainer : ""}`}
				disableReposition
				content={({ position, targetRect, popoverRect }) => (
					<ArrowContainer
						position={position}
						targetRect={targetRect}
						popoverRect={popoverRect}
						arrowSize="10"
						arrowStyle={{ left: "10px" }}
					>
						<ArrowContainer
							position={position}
							targetRect={targetRect}
							popoverRect={popoverRect}
							arrowSize="10"
							arrowStyle={{ left: "10px" }}
						>
							<div className={styles.errorText}>{getMessage()}</div>
						</ArrowContainer>
					</ArrowContainer>
				)}
				position="bottom"
				isOpen={isNonEmptyString(getMessage())}
				align="start"
			>
				{children}
			</Popover>
			}
		</div>
	);
};

FormError.propTypes = {
	children: PropTypes.node,
	name: PropTypes.string.isRequired,
	formik: PropTypes.shape({
		errors: PropTypes.object
	})
};

export default connect(FormError);