import moment from "moment";
import { createHistory } from "@reach/router";
import * as queryString from "query-string";
import { PostSlugValidation } from "schema/pageQueries";
import { isNumber, isFunction, isNonEmptyArray, removeFalsyValues, isNonEmptyString } from "helpers";

const PAGE_SIZE = 20;

const history = createHistory(window);

export const extractFilterGroupByTitle = (filterGroups, title) => isNonEmptyArray(filterGroups)
	? filterGroups.find(group => group.title === title)
	: undefined;

export const assembleFilterVariables = ({filters, filterGroups, offsets}) => {
	const preparedFilters = {
		skipArticles: false,
		skipGalleries: false,
		skipVideos: false,
		articleOffset: offsets?.article || 0,
		galleryOffset: offsets?.gallery || 0,
		videoOffset: offsets?.video || 0,
		startDate: "1900-01-01T00:00:00",
		endDate: `${moment().format("YYYY-MM-DDTHH:mm:ss")}Z`,
	};

	for (let key in filters) {
		if (filters.hasOwnProperty(key)) {

			const filterGroup = extractFilterGroupByTitle(filterGroups, key);
			const targetField = filterGroup?.targetField;
			const filterValue = filters[key];

			switch (targetField) {
				case "publishedDate":
					const matches = filterValue.match(/^(?<year>[0-9]{4})\s+[\sa-zA-Z0-9\u00C0-\u017F]+$/);

					if (matches?.groups?.year) {
						preparedFilters.startDate = "1900-01-01T00:00:00";
						preparedFilters.endDate = `${matches.groups.year}-12-31T23:59:59Z`;
					} else {
						const currentYear = moment().format("YYYY");
						const date = moment(`${filterValue}-01-01T00:00:00Z`);

						preparedFilters.startDate = date.utc().format("YYYY-01-01T00:00:00Z");
						preparedFilters.endDate = filterValue === currentYear
							? `${moment().format("YYYY-MM-DDT23:59:59")}Z`
							: `${date.utc().format("YYYY-12-31T23:59:59")}Z`;
					}
					break;
				case "postFormat":
					preparedFilters.skipArticles = !["article", "artigo", "artículo"].includes(filterValue.toLowerCase());
					preparedFilters.skipGalleries = !["gallery", "galeria", "galería"].includes(filterValue.toLowerCase());
					preparedFilters.skipVideos = !["video", "vídeo"].includes(filterValue.toLowerCase());
					break;
				default:
					preparedFilters[targetField] = filterValue;
			}
		}
	}

	return preparedFilters;
};

export const parseQueryString = () => queryString.parse(window?.location?.search) || {};

export const queryStringifyFilters = filters => {
	const filterString = queryString.stringify(filters);

	if (isNonEmptyString(filterString)) {
		history.navigate(`?${filterString}`);
	}
};

export const resetQueryString = () => history.navigate("?");

export const combineAndSortPosts = ({ articles = [], galleries = [], videos = [] }) => articles.concat(galleries).concat(videos).sort((item1, item2) => {
	return new Date(item2.publishedDate) - new Date(item1.publishedDate);
});

export const validatePostSlugs = (apolloClient, postSlugs) => {
	return apolloClient.query({
		query: PostSlugValidation,
		variables: { postSlugs: removeFalsyValues(postSlugs) }
	}).then(({ data: { articleCollection, galleryCollection, videoCollection } }) => {
		const output = {};
		const posts = [
			...(isNonEmptyArray(articleCollection?.items) ? articleCollection.items : []),
			...(isNonEmptyArray(galleryCollection?.items) ? galleryCollection.items : []),
			...(isNonEmptyArray(videoCollection?.items) ? videoCollection.items : []),
		];

		posts.forEach(post => output[post.sys.id] = post);

		return output;
	});
};

const runQuery = ({ apolloClient, pageSize, filterResults: incomingFilterResults, ...queryOptions }) => {
	const shouldAllowPost = isFunction(incomingFilterResults) ? incomingFilterResults : () => true;

	return new Promise((resolve, reject) => {
		const entries = [];
		let numEntries = 0;
		const requiredResults = isFinite(pageSize) ? pageSize : PAGE_SIZE;
		const offsets = { article: 0, gallery: 0, video: 0 };

		const shouldFetchPinnedItems = queryOptions?.variables?.includeLanding;
		const shouldFetchPosters = queryOptions?.variables?.includePosters;

		apolloClient.query(queryOptions)
			.then(async ({ data }) => {
				const {
					articleCollection,
					galleryCollection,
					videoCollection,
					landingCollection,
					moduleAlbumPosterCollection
				} = data || {};

				const pinnedItems = landingCollection?.items[0]?.pinnedContentCollection?.items || [];
				const numPinnedItems = pinnedItems.length;
				const posters = moduleAlbumPosterCollection?.items || [];

				const posts = combineAndSortPosts({
					articles: articleCollection?.items || [],
					galleries: galleryCollection?.items || [],
					videos: videoCollection?.items || []
				});

				const postSlugs = [];
				posts.forEach(post => {
					postSlugs.push(post.routeSlug);
				});

				const validPostIds = await validatePostSlugs(apolloClient, postSlugs);
				const removeMissingPosts = post => !!validPostIds[post.sys.id];

				posts.filter(removeMissingPosts).forEach(post => {
					if (numEntries < (requiredResults - numPinnedItems)) {
						const postType = post.__typename.toLowerCase();

						if (postType in offsets) {
							offsets[postType] += 1;
						}

						if (shouldAllowPost(post, { pinnedItems })) {
							entries.push(post);
							numEntries += 1;
						}
					}
				});

				const returnData = {
					entries: entries.slice(0, requiredResults),
					...(shouldFetchPinnedItems ? { pinnedItems } : {}),
					...(shouldFetchPosters ? { posters } : {}),
					meta: {
						offsets: offsets,
						totals: {
							article: articleCollection?.total || 0,
							gallery: galleryCollection?.total || 0,
							video: videoCollection?.total || 0
						}
					}
				};

				resolve(returnData);
		});

	});
};

export const getResultPage = ({ apolloClient, query, variables, pageSize, offsets: incomingOffsets, ...otherOptions }) => {
	const targetEntryCount = isNumber(pageSize) ? pageSize : PAGE_SIZE;
	let remainingEntries = targetEntryCount;
	let iterations = 1;
	let hasMoreArticles = true;
	let hasMoreGalleries = true;
	let hasMoreVideos = true;
	let offsets = {
		...{ article: 0, gallery: 0, video: 0 },
		...incomingOffsets
	};

	let returnValue = {
		posts: []
	};
	const hasMorePosts = () => hasMoreArticles || hasMoreGalleries || hasMoreVideos;
	const iterationLimit = 100;
	const today = moment();


	return new Promise(async (resolve, reject) => {
		do {
			const mergedVariables = {
				skipArticles: !hasMoreArticles,
				skipGalleries: !hasMoreGalleries,
				skipVideos: !hasMoreVideos,
				includeLanding: false,
				includePosters: false,
				startDate: "1900-01-01T00:00:00Z",
				endDate: `${today.format("YYYY-MM-DDTHH:mm:ss")}Z`,
				...variables,
				articleOffset: offsets.article,
				galleryOffset: offsets.gallery,
				videoOffset: offsets.video,
				...(iterations > 1 ? { includeLanding: false, includePosters: false } : {}) /* only want to pull on first attempt */
			};

			await runQuery({
				query: query,
				variables: mergedVariables,
				pageSize: remainingEntries,
				apolloClient: apolloClient,
				...otherOptions
			})
				.then(({ entries, meta, pinnedItems, posters }) => {
					const { offsets: resultOffsets, totals } = meta || {};
					const numPinned = isNonEmptyArray(pinnedItems) ? pinnedItems.length : 0;
					returnValue.posts.push(...entries);
					remainingEntries = Math.max(targetEntryCount - returnValue.posts.length - numPinned, 0);

					if (Array.isArray(pinnedItems) && !isNonEmptyArray(returnValue.pinnedItems)) {
						returnValue.pinnedItems = pinnedItems
					}

					if (Array.isArray(posters) && !isNonEmptyArray(returnValue.posters)) {
						returnValue.posters = posters
					}

					offsets.article += resultOffsets.article;
					offsets.gallery += resultOffsets.gallery;
					offsets.video += resultOffsets.video;
					hasMoreArticles = offsets.article < totals.article;
					hasMoreGalleries = offsets.gallery < totals.gallery;
					hasMoreVideos = offsets.video < totals.video;
				})
				.catch((x) => {
					console.error("Something failed in runQuery: %s", x.message);
					reject(x)
				});
		} while (remainingEntries > 0 && hasMorePosts() && iterations++ < iterationLimit);

		resolve({
			...returnValue,
			offsets: offsets
		});
	});
};