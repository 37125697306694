import React, { useEffect, useState } from "react";
import { Query } from "react-apollo";
import { useGlobalState } from "helpers";

const ConnectedComponent = ({ query, success, errorCheck = () => false, variables = null, errorPolicy = "all" }) => {
	const [ isLanguageReady, setIsLanguageReady ] = useState(false);
	const [ selectedLanguage ] = useGlobalState("selectedLanguage");
	let vars = variables || {};
	vars.locale = selectedLanguage;

	useEffect(() => {
		if (selectedLanguage) {
			setIsLanguageReady(true);
		}
	}, [selectedLanguage]);

	return !isLanguageReady
		? ""
		: (
		<Query query={query} variables={variables} errorPolicy={errorPolicy}>
			{
				({ loading, error, data, client }) => {

					if (loading) {
						return ""
					}

					if (error || errorCheck(data)) {
						return <div>error!</div>
					}

					return success(data, client);
				}
			}
		</Query>
	);
};

export default ConnectedComponent;