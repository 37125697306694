import React from "react";
import { useDictionaryContent } from "hooks/shell";
import { ShareBarQuery } from "schema/fragments/Poster";
import { getLocaleId, isNonEmptyString } from "helpers";
import { FacebookShareButton, TwitterShareButton } from 'react-share';

import ConnectedComponent from "components/ConnectedComponent"

import FacebookIcon from "globals/images/facebook-white.png";
import TwitterIcon from "globals/images/twitter-white.png";

import styles from "./styles.css";

const shareUrlHash = {
	en: "/share/en/share-poster.htm",
	es: "/share/es/compartir-cartel.htm",
	pt: "/share/pt/compartilhar-cartaz.htm"
};


const ShareBar = () => {

	const [ dictionaryContent ] = useDictionaryContent();

	const localeId = getLocaleId();

	const success = ({ moduleAlbumCollection }) => {
		const shareUrl = localeId in shareUrlHash
			? `${window.location.protocol}//${window.location.host}${shareUrlHash[localeId]}`
			: undefined;
		const content = moduleAlbumCollection?.items[0];

		return isNonEmptyString(shareUrl) ? (
				<div className={styles.socialBar}>
					<span>{dictionaryContent?.labelShare}</span>
					<FacebookShareButton url={shareUrl} quote={content.shareTitle}>
						<button className={styles.socialIcon}><img src={FacebookIcon} alt="Facebook" /></button>
					</FacebookShareButton>

					<TwitterShareButton url={shareUrl} title={content.shareTitle}>
						<button className={styles.socialIcon}><img src={TwitterIcon} alt="Twitter" /></button>
					</TwitterShareButton>
				</div>
			) : "";
		};

	return <ConnectedComponent query={ShareBarQuery} success={success} />
};

export default ShareBar;
