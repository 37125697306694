import React from "react";
import PropTypes from "prop-types";
import { getLocaleId } from "helpers/i18n";
import { Link } from "@reach/router";

const LocaleLink = ({ children, to, disabled, ...rest }) => {
  const locale = getLocaleId();
  const onClick = (ev) => {
    if (disabled) {
      ev.preventDefault();
    }
  };

  return <Link to={`/${locale}${to}`} onClick={onClick} {...rest}>{children}</Link>;
};

LocaleLink.propTypes = {
  children: PropTypes.node,
  to: PropTypes.string,
  disabled: PropTypes.bool
};

export default LocaleLink;