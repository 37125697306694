import React from "react";
import PropTypes from "prop-types"
import { Field } from "formik";
import FormError from "components/Forms/Elements/FormError";

import styles from "./styles.css";


const Email = ({ id, name, label, required, placeholder, bodyErrors, hasError }) => {
	return (
		<div data-component="Forms\Elements\Email">
			{label &&
			<label htmlFor={id ? id : name} className={styles.inputLabel}>
				{required && "*"}{label}
			</label>
			}

			<FormError name={name} appendToBody={!!bodyErrors}>
				<Field component="input" type="email" id={id || name} name={name} className={`${styles.textInput} ${hasError ? styles.error : ""}`} placeholder={placeholder} />
			</FormError>
		</div>
	)
};

Email.propTypes = {
	name: PropTypes.string.isRequired,
	id: PropTypes.string,
	label: PropTypes.string,
	placeholder: PropTypes.string,
	required: PropTypes.bool,
	bodyErrors: PropTypes.bool
};

export default Email;