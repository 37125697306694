import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import { useQuery, useApolloClient } from "react-apollo-hooks";
import { useCategoryFilters } from "hooks/filters"
import {
	useGlobalState,
	lockBackground,
	keyCodes,
	isNonEmptyArray,
	isFunction,
	isBool,
	getLocaleId,
	getEnglishCategorySlug,
	scrollWindowToTop,
	isNonEmptyString
} from "helpers";
import { useDictionaryContent } from "hooks/shell";
import { GetCategoryFilters } from "schema/filter";
import Cta from "components/Cta";

import ArrowThin from "./images/dropdown-arrow-thin.svg";
import styles from "./styles.css";


const parseFilterGroups = categoryData => {
	const filterGroupsCollection = categoryData?.categoryCollection?.items[0]?.filterGroupsCollection || {};

	return isNonEmptyArray(filterGroupsCollection.items)
		? filterGroupsCollection.items.filter(item => !!item)
		: [];
};

let previousScrollY = null;

const Filter = ({ categorySlug, initialFilters, onApply, onReset }) => {
	const [ isInitialized, setIsInitialized ] = useState(false);
	const [ content ] = useDictionaryContent();
	const apollo = useApolloClient();
	const [englishFilterData, setEnglishFilterData] = useState(null);
	const isEnglish = getLocaleId() === "en";

	const { data, loading } = useQuery(GetCategoryFilters, {
		variables: { categorySlug: categorySlug },
		errorPolicy: "all"
	});

	const [navOpen] = useGlobalState("navOpen");
	const [scrollY] = useGlobalState("scrollY");
	const [selectedRadios, setSelectedRadios] = useCategoryFilters(categorySlug);
	const [isFilterOpen, setFilterOpen] = useState(false);
	const [isFilterHidden, setFilterHidden] = useState(false);
	const filterGroups = data ? parseFilterGroups(data) : [];
	const labelFilters = content?.labelFilters;
	const labelApply = content?.labelApply;
	const labelReset = content?.labelReset;
	const hasFilterGroups = !loading && isNonEmptyArray(filterGroups);
	const filterRef = useRef(null);

	const fetchEnglishData = () => {
		if (!isEnglish) {
			const englishSlug = getEnglishCategorySlug(categorySlug);

			apollo.query({
				query: GetCategoryFilters,
				variables: { locale: "en-US", categorySlug: englishSlug },
				errorPolicy: "ignore"
			})
				.then(({ data }) => {
					setEnglishFilterData(data);
				});
		}
	};

	const select = (groupKey, value) => {
		if((groupKey in selectedRadios) && (selectedRadios[groupKey] === value)) {
			const mutableRadios = {...selectedRadios};
			delete mutableRadios[groupKey];
			setSelectedRadios(mutableRadios);
		} else {
			setSelectedRadios({
				...selectedRadios,
				[groupKey]: value
			});
		}
	};

	const reset = () => {
		setSelectedRadios({});

		if (isFunction(onReset)) {
			onReset();
		}

		scrollWindowToTop();

		toggleFilter(false);
	};

	const apply = () => {
		if (isFunction(onApply)) {
			const englishFilters = isEnglish ? selectedRadios : {};
			const parsedEnglishGroups = isEnglish ? filterGroups : parseFilterGroups(englishFilterData);

			if (!isEnglish) {
				for (let key in selectedRadios) {
					if (selectedRadios.hasOwnProperty(key)) {
						const filterGroup = filterGroups.find(group => group.title === key);
						const filterGroupId = filterGroup.sys.id;
						const englishFilterGroup = parsedEnglishGroups.find(engGroup => engGroup.sys.id === filterGroupId);
						const englishGroupOptions = englishFilterGroup?.filtersCollection?.items || [];
						const selectedOption = filterGroup.filtersCollection.items.find(option => option.title === selectedRadios[key]);
						const optionId = selectedOption.sys.id;
						const englishSelectedOption = englishGroupOptions.find(option => option.sys.id === optionId);

						if (isNonEmptyString(englishFilterGroup?.title) && isNonEmptyString(englishSelectedOption?.title)) {
							englishFilters[englishFilterGroup.title] = englishSelectedOption.title;
						}
					}
				}
			}

			onApply({ filters: englishFilters, filterGroups: parsedEnglishGroups, localeFilters: selectedRadios });
		}

		scrollWindowToTop();

		toggleFilter(false);
	};

	const toggleFilter = (bool) => {
		const newIsOpen = isBool(bool) ? bool : !isFilterOpen;

		setFilterOpen(newIsOpen);
	};

	const countFilters = () => {
		return Object.keys(selectedRadios).length
	};

	const escFunction = (event) => {
		if(event.keyCode === keyCodes.esc) {
			setFilterOpen(false)
		}
	};

	useEffect(() => {
		setFilterHidden((previousScrollY < scrollY && scrollY > 100));
		previousScrollY = scrollY;
	}, [scrollY]);

	useEffect(() => {
		if(navOpen) {
			setFilterOpen(false)
		}
	}, [navOpen]);

	useEffect(() => {
		lockBackground(isFilterOpen, filterRef.current);
	}, [isFilterOpen]);

	useEffect(() => {
		if (isInitialized) {
			reset();
		}
	}, [categorySlug]);

	useEffect(() => {
		if (Object.keys(initialFilters).length) {
			setSelectedRadios({ ...initialFilters });
		}
	}, [initialFilters]);

	useEffect(() => {
		window.addEventListener("keyup", escFunction);

		return () => window.removeEventListener("keyup", escFunction);
	}, []);

	useEffect(fetchEnglishData, []);

	useEffect(() => setIsInitialized(true), []);

	return (
		<>
			<div data-component="Filter" className={isFilterHidden ? styles.hiddenFilter : styles.filter}>
				<div className={styles.filterBar} onClick={toggleFilter}>
					<span className={styles.filterTitle}>{labelFilters}</span>
					{(countFilters() > 0) && <span className={styles.count}>{countFilters()}</span>}
					<div className={`${isFilterOpen ? styles.arrowFlipped : ""} ${styles.arrow}`}>
						<ArrowThin/>
					</div>
				</div>
				<div className={`${!isFilterOpen ? styles.closed : ""} ${styles.expandedSection}`} ref={filterRef}>
					<div className={styles.filterContainer}>
						{hasFilterGroups && filterGroups.map((filterGroup, index) => {
							const groupKey = filterGroup.title;

							return (
								<div key={index} className={styles.filterGroup}>
									<div className={styles.title}>{filterGroup.title}</div>
									{filterGroup.filtersCollection.items.map((filter, index) => {
										const filterKey = filter.title;

										return (
											<li key={index} className={styles.filterItem} onClick={() => {
												return select(groupKey, filterKey)
											}}>
												<input type="radio" name={filterGroup.title} id={filter.title} className={styles.radio} checked={selectedRadios[groupKey] === filterKey} />
												<label htmlFor={filter.title} onClick={ev => ev.preventDefault()}>{filter.title}</label>
											</li>
										)
									})}
								</div>
						)})}
					</div>
					<div className={styles.buttonContainer}>
						<Cta hasBorder>
							<button disabled={!hasFilterGroups} onClick={reset} className={styles.reset}>{labelReset}</button>
						</Cta>
						<Cta color="Black">
							<button disabled={!hasFilterGroups} onClick={apply}>{labelApply}</button>
						</Cta>
					</div>
				</div>
			</div>
			<div className={`${!isFilterOpen ? styles.overlayHidden : ""} ${styles.overlay}`} onClick={toggleFilter}/>
		</>
	)
};

Filter.propTypes = {
	categorySlug: PropTypes.string,
	onApply: PropTypes.func
};

export default Filter;