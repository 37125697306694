import React from "react";
import ReactDOM from "react-dom";
import * as serviceWorker from "./serviceWorker";
import ApolloClient from "apollo-client";
import { ApolloProvider } from "react-apollo";
import { ApolloProvider as ApolloHooksProvider } from "react-apollo-hooks";
import { InMemoryCache, IntrospectionFragmentMatcher } from "apollo-cache-inmemory";
import { Helmet } from "react-helmet";
import { HttpLink } from "apollo-link-http";
import { onError } from "apollo-link-error";
import { ApolloLink } from "apollo-link";
import { LocaleLink } from "links";
import TagManager from "react-gtm-module";
import { GlobalStateProvider, useGlobalState } from "helpers";
import Routing from "components/Routing";
import ScrollListener from "components/ScrollListener";
import introspectionQueryResultData from "./schema/fragmentTypes.json";

import "./index.css";

const {
	REACT_APP_SPACE_ID: SPACE_ID,
	REACT_APP_ACCESS_TOKEN: ACCESS_TOKEN,
	REACT_APP_ENVIRONMENT: ENVIRONMENT
} = process.env;

TagManager.initialize({
	gtmId: "GTM-MP6LXGL",
	events: {}
});

const fragmentMatcher = new IntrospectionFragmentMatcher({
	introspectionQueryResultData
});

const cache = new InMemoryCache((fragmentMatcher));

const client = new ApolloClient({
	link: ApolloLink.from([
		ApolloLink.split(() => true, LocaleLink),
		onError(({ graphQLErrors, networkError }) => {
			if (graphQLErrors) {
				graphQLErrors.map(({ message, locations, path }) =>
					console.log(
						`[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
					)
				);
			}
			if (networkError) {
				console.log(`[Network error]: ${networkError}`);
			}
		}),
		new HttpLink({
			uri: `https://graphql.contentful.com/content/v1/spaces/${SPACE_ID}/environments/${ENVIRONMENT}`,
			credentials: "same-origin",
			headers: {
				Authorization: `Bearer ${ACCESS_TOKEN}`
			}
		})
	]),
	defaultOptions: {
		watchQuery: {
			errorPolicy: "all"
		}
	},
	cache
});

const SiteHelmet = () => {
	const [ pageTitle ] = useGlobalState("pageTitle");

	return (
		<Helmet>
			<title>{pageTitle}</title>
		</Helmet>
	);
};

ReactDOM.render((
	<ApolloProvider client={client}>
		<ApolloHooksProvider client={client}>
			<GlobalStateProvider>
				<ScrollListener/>
				<SiteHelmet/>
				<Routing/>
			</GlobalStateProvider>
		</ApolloHooksProvider>
	</ApolloProvider>
), document.getElementById("root"));

serviceWorker.unregister();