import React, { useRef, useState, useEffect } from "react";
import PropTypes from "prop-types";

import styles from "./styles.css";


const PrecisionScroller = ({ itemSelector, currentIndex, children, className }) => {
	const [ translation, setTranslation ] = useState(0);
	const ref = useRef(null);

	const classes = `${styles.precisionScroller} ${className}`;

	useEffect(() => {
		if (isFinite(currentIndex) && ref.current) {
			const anchorPosition = ref.current?.getBoundingClientRect()?.left;
			const tabElements = ref.current.querySelectorAll(itemSelector);
			const currentTabElement = tabElements[currentIndex];
			const itemLeft = currentTabElement?.getBoundingClientRect()?.left || anchorPosition;
			setTranslation(0 - (itemLeft - anchorPosition));
		}
	}, [ref, currentIndex]);

	const translationObject = {
		transform: `translateX(${translation}px)`
	};

	return (
		<div ref={ref} data-component="PrecisionScroller" className={classes} style={translationObject}>
			{children}
		</div>
	);
};

PrecisionScroller.propTypes = {
	itemSelector: PropTypes.string.isRequired,
	currentIndex: PropTypes.number,
	children: PropTypes.node,
	className: PropTypes.string
};

export default PrecisionScroller;