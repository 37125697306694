import React from "react";
import PropTypes from "prop-types";

import styles from "./styles.css";

const BasePadding = ({ children, desktop, mobile }) => {

  const crunchClasses = ({ desktop, mobile }) => {
    /**
     * This funky ternary is to prevent the accidental application of padding. Without the explicit checks for left/right,
     * it's possible to specify { left: false, right: false } and still have .desktopPadding add padding to both, which is bad
     **/
    const desktopPadding = !desktop.disabled && desktop.left !== false && desktop.right !== false
      ? styles.desktopPadding
      : "";

    const desktopPaddingRight = desktop.right ? styles.desktopPaddingRight : "";
    const desktopPaddingLeft = desktop.left ? styles.desktopPaddingLeft : "";
    const desktopFullHeight = desktop.fullHeight ? styles.desktopFullHeight : "";

    const mobilePadding = !mobile.disabled && mobile.left !== false && mobile.right !== false
      ? styles.mobilePadding
      : "";

    const mobilePaddingRight = mobile.right ? styles.mobilePaddingRight : "";
    const mobilePaddingLeft = mobile.left ? styles.mobilePaddingLeft : "";
    const mobileFullHeight = mobile.fullHeight ? styles.mobileFullHeight : "";

    return `${desktopPadding} ${desktopPaddingRight} ${desktopPaddingLeft} ${desktopFullHeight} ${mobilePadding} ${mobilePaddingRight} ${mobilePaddingLeft} ${mobileFullHeight}`;
  };

  return (
    <div data-component="BasePadding" className={crunchClasses({ desktop, mobile })}>
      {children}
    </div>
  )
};

BasePadding.propTypes = {
  children: PropTypes.node,
  desktop: PropTypes.shape({
    left: PropTypes.bool,
    right: PropTypes.bool,
    disabled: PropTypes.bool,
    fullHeight: PropTypes.bool
  }),
  mobile: PropTypes.shape({
    left: PropTypes.bool,
    right: PropTypes.bool,
    disabled: PropTypes.bool,
    fullHeight: PropTypes.bool
  })
};

BasePadding.defaultProps = {
  desktop: {
    left: true,
    right: true,
    disabled: false,
    fullHeight: false
  },
  mobile: {
    left: true,
    right: true,
    disabled: false,
    fullHeight: false
  }
};

export default BasePadding;