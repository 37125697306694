import React, { useEffect } from "react";
import { GeneralQuery } from "schema/fragments/Policy";
import { useGlobalState, isNonEmptyArray, getLocaleId } from "helpers";

import BasePadding from "components/BasePadding";
import ConnectedComponent from "components/ConnectedComponent"
import Container from "components/Container";
import RichText from "components/RichText";
import { Redirect }  from "@reach/router";

import styles from "./styles.css";

const General = ({ policy, path }) => {

	const routeSlug = path.replace(/^\//, "");

	const [, setIsGeneral ] = useGlobalState("isGeneral");
	useEffect(() => {
		setIsGeneral(true);
		return () => setIsGeneral(false);
	}, [setIsGeneral]);

	const success = ({ generalCollection }) => {

		return isNonEmptyArray(generalCollection?.items) ? (
			<div data-component="Container\Policy" className={styles.cookie}>
				<Container>
					<BasePadding>
						<RichText data={generalCollection?.items[0].content} />
					</BasePadding>
				</Container>
			</div>
			)
			:
			<Redirect to={`/${getLocaleId()}/404`} noThrow />;
		};

	return <ConnectedComponent query={GeneralQuery} success={success} variables={{ routeSlug: routeSlug}} />
};

export default General;
